@import 'src/assets/sass/variables.scss';
.page-banner {
	&-img {
		&__background {
			object-fit: cover;
		}
	}
}

.page-banner-img {
	margin-bottom: -6px;
}

.banner-overlay {
	position: absolute;
	width: 100%;
	height: 100%;
}

.page-banner-title {
	position: absolute;
	left: 0;
}

.page-banner-title-desktop {
	bottom: 48px;
	width: 450px;
	left: 60px;
}

.page-banner-title-tablet {
	bottom: 48px;
	width: 300px;
	left: 40px;
}

.page-banner-title-mobile {
	bottom: 32px;
	left: 17.5%;
	width: 65%;
}

.virus-bug {
	position: absolute;
	top: 25%;
	right: calc(50% - 315px);

	img {
		width: 100%;
		height: 100%;
	}

	&.tablet { 
		@media screen and (min-width: 900px) {
			height: 18.75%;
			top: 21.96%;
			right: 16.07%;
		}
	}
	&.mobile {
		top: 15%;
		right: 4%;
		height: 18%;

		@media screen and (min-width: 320px) {
			top: 15.5%;
			right: 1%;
		}
	}
}

.virus-bug-link {
	position: absolute;
	top: 30%;
	bottom: 30%;
	right: 35%;
	left: 25%;
}
