@import 'src/assets/sass/variables';
@import 'src/assets/sass/mixins';

.FAQ-container-desktop {
	margin-top: 450px;
}

.FAQ-container-tablet,
.FAQ-container-mobile {
	margin-top: 200px;
}

.faq-link:hover {
	color: black;
}

.faq-title {
  font-family: Slalom Sans;
  text-align: center;
	color: $color-secondary-300;
  padding-bottom: 16px;
  padding-top: 48px;

  p {
    font-size: 24px;
    font-weight: 700;
    line-height: 28.8px;
  }
}

.faq-header {
	@include cardTitle();
	text-transform: none;
	z-index: 99;

	p {
		text-transform: uppercase;
	}
}

.faq-header-large {
	font-size: 18px;
	line-height: 22px;
	z-index: 99;
}

.faq-content-wrapper {
  display: flex;
  margin: 24px;

  &.sticky {
    .table-of-contents {
      position: fixed;
      top: 40px;
    }
    .faq-sections-wrapper {
      margin-left: 465px;

      @media screen and (max-width: $breakpoint-md) {
        display: none;
      }
    }
  }
}

.table-of-contents {
  align-self: baseline;
  margin-right: 24px;
	margin-top: 48px;
  padding: 24px 16px;
  width: 440px;
	@include card();
	background: $color-primary-700;
  border: 3px solid $color-secondary-400;
  border-radius: 10px;

  // Keeps the TOC container pinned to the top when scrolling
  position: sticky;
  top: 48px;


  @media screen and (max-width: $breakpoint-md) {
    display: none;
  }

  .title {
    font-size: 16px;
  }

  a {
    display: block;
    font-size: 16px;
    margin-bottom: 16px;
    padding-left: 6px;
		color: $color-secondary-300;

    &.active {
      border-left: 2px solid;
    }
  }
}

.faq-sections-wrapper {
  margin-bottom: 48px;
  display: block;
  width: calc(100% - 465px);
  @media screen and (max-width: $breakpoint-md) {
    width: 100%;
  }
  &:last-child {
    margin-bottom: 0;
  }
}
