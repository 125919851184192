@import "src/assets/sass/variables.scss";
@import "src/assets/sass/mixins.scss";

.background-wrapper {
  background: $color-gray-gradient-background, url('../../assets/images/Background.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: scroll;
}

.page-wrapper {
  display: flex;
  flex-direction: column;

  &__content {
    &-container {
      padding: 48px 58px;

      @media screen and (max-width: 1182px) {
        padding: 32px $length-content-container-tablet-padding;
      }

      @media screen and (max-width: 705px) {
        padding: 32px $length-content-container-mobile-padding;
      }
    }
  }

  &__banner {
    position: relative;
    width: 100%;
    height: 592px;
    z-index: 100;
    z-index: 100;
    background-color: #121c49;
    margin-top: 80px;

    @media screen and (max-width: 705px) {
      height: 325px;
      margin-top: 80px;
    }

    @media screen and (max-width: 320px) {
      height: 405px;
    }
  }
}

.page-wrapper-content {
  position: relative;
  background-position: center;
  background: $color-green-gradient-dark-vertical1;
}

.page-bg-container {
  height: 100%;
  width: 100%;
}

.page-banner {
  width: 100%;
  height: 100%;

  &-img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    &__text {
      z-index: 999;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      @media screen and (max-width: 705px) {
        height: 325px;
        margin-top: -80px;
      }

      @media screen and (max-width: 1082px) {
        width: 100%;
      }
    }
  }
}