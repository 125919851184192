@import 'src/assets/sass/variables.scss';
@import 'src/assets/sass/mixins';

/* Dropdown Styles */
.dropdown-label {
	color: $color-white;
}

.dropdown-container {
	width: unset !important;
	display: flex;
}

.ui.multiple.dropdown {
	position: relative;
	//border-radius: 1px;
	width: 175px;
	height: 75px;
	background-color: $color-dark-purple;
	border: 1px solid rgba(255, 255, 255, 0.8);
	backdrop-filter: blur(40px);
	border-radius: 25px;
	z-index: -1;
	overflow: visible !important;
}

.ui.dropdown .menu .selected.item,
.ui.dropdown.selected {
	font-family: Slalom Sans;
	font-style: normal;
	font-weight: 900;
	font-size: 16px;
	line-height: 20px;
	color: $color-white;
	z-index: 10;
}

.ui.dropdown .menu > .item:hover {
	color: $color-secondary-400 !important;
	text-shadow: 0px 0px 15px $text-shadow;
	font-weight: 700;
}

.ui.dropdown .menu > .item.selected .text:hover {
	color: $color-white !important;
}

.ui.dropdown .menu > .item {
	background-color: $color-primary-700;
	color: $color-white !important;
}

.ui.dropdown .menu > .item:hover.active {
	background-color: $color-primary-700;
	color: $color-white;
}

.ui.dropdown .menu > .item {
	height: 50px;
	border-top: unset !important;
	color: $color-dark-purple;
}

a.ui.active.label:hover,
a.ui.labels .active.label:hover {
	//background: $color-white;
	//opacity: 0.1;
}

.ui.selection.dropdown,
.ui.selection.active.dropdown,
.ui.active.visible.selection.dropdown {
	background-color: $color-primary-700;
	border-color: $primary-border;
	border-radius: 10px !important;
}

.ui.selection.active.dropdown .menu {
	background: $color-primary-700;
	backdrop-filter: blur(20px);
	box-sizing: border-box;
	opacity: 1;
	min-height: auto;
	max-height: 350px;
	padding : 0px 16px 0px 16px;
	border-bottom-width: 1px !important;
}

// .ui.dropdown > .text,
.ui.selection.visible.dropdown > .text:not(.default),
.ui.selection.active.dropdown,
.ui.selection.dropdown:focus,
.ui.selection.dropdown:hover,
.ui.selection.active.dropdown:hover,
.ui.dropdown.active.label:hover,
.ui.selection.active.dropdown:hover .menu {
	color: $color-secondary-400;
	font-weight: 700;
	border-radius: 10px !important;
	padding: 0px 16px;
}

.ui.dropdown > .text,
.ui.dropdown .menu > .item.selected {
	color: $color-white !important;
	font-weight: 400;
	line-height: 24px;
}


.ui.selection.dropdown .menu > .item {
	font-size: 16px;
	//padding: 1rem 1rem !important;
}

.ui.selection.dropdown .menu > .item:nth-last-child(n+2)::before {
	content: '';
	position: absolute;
	height: 1px;
	bottom: 0;
	background-color: $color-secondary-400;
	width: calc(100% - 28px);
}

.ui.selection.dropdown {
	width: 100%;
	height: 100%;
	background: $color-primary-700;
	border: 1px solid $color-secondary-400 !important;
	border-radius: 10px !important;
	backdrop-filter: blur(40px);
	z-index: 0;
	min-width: initial;
	padding: 0px 16px;
	
}

.ui.selection.dropdown:focus {
	// backdrop-filter: blur(40px);
}

.ui.dropdown > .dropdown.icon:before {
	content: '';
	pointer-events: all;
}

.ui.active.selection.dropdown > .dropdown.icon,
.ui.visible.selection.dropdown > .dropdown.icon {
	//background-image: url('../../assets/images/up-arrow.svg');
	transform: rotate(180deg);
}

.ui.dropdown > .dropdown.icon {
	background-image: url('../../assets/images/expand_more.svg');
	background-size: auto;
	background-repeat: no-repeat;
	//top: unset !important;
	margin: 0 !important;
}

.ui.multiple.dropdown > .label {
	margin: 10px 4px 10px 4px;
	height: 32px;
	padding: 4px 24px 4px 12px;
	text-align: left;
	border-radius: 25px;
	background: $color-secondary-300;
	border: none;
	font-size: 16px;
	line-height: 24px;
	color: $color-black;
	white-space: nowrap;
	position: relative;
	text-overflow: ellipsis;
	overflow: hidden;
	text-decoration: none;
	font-weight: 400;
	font-family: Slalom Sans;
}

a.ui.active.label:hover,
a.ui.labels .active.label:hover {
	//opacity: 0.7;
}

i.icon.delete:before {
	content: '';
	pointer-events: all;
}

.ui.label > .close.icon,
.ui.label > .delete.icon {
	background-image: url('../../assets/images/hacktober-23-dropdown-filter-delete-icon.png');
	background-size: contain;
	background-repeat: no-repeat;
	width: 14px;
	height: 14px;
	//margin-right: 0;
	//margin-left: 1rem;
	font-size: unset;
	opacity: unset;
	//position: absolute;
	//top: 5px;
	//left: 5px;
}

.ui.selection.dropdown .menu {
	border-radius: 10px;
	border: 1px solid $color-secondary-400 !important;
	//margin-top: 7px;
	//margin-bottom: 7px;
	width: calc(100% + 4px);
	min-width: calc(100% + 4px);
	//margin-left: -2px;
	padding : 0px 16px 0px 16px!important;
}
