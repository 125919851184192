@import "src/assets/sass/variables.scss";
@import "src/assets/sass/mixins.scss";

footer {
	@include fontStandard();
	position: relative;
	width: 100%;
	height: 90px;
	gap: 200px;
	color: $color-white;
	text-align: center;
	display: flex;
	flex-direction: row;
	justify-content: center;
	cursor: default;
	background: $color-primary-700;
	padding-left: 20px;
	padding-right: 20px;
}

.footer-text-container {
	display: flex;
	align-items: center;
	flex-direction: row;
	text-align: left;
	width: 280px;
	gap: 1.5px;
}

.footer-text-container a {
	color: $color-white;
	font-weight: 600;
}

.flag-icon,
.chat-icon,
.mail-icon {
  line-height: 10px;
  padding-right: 16px;
}

.footer-tablet {
	height: 136px;
	gap: 8px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	.flag-icon,
	.chat-icon,
	.mail-icon {
		height: 24px;
	}
}

.footer-tablet .footer-text-container {
	width: 60%;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	position: relative;
	p {
		font-size: 14px;
		line-height: 19px;
	}
}

.footer-mobile {
	height: 206px;
	gap: 8px;
	flex-direction: column;
	align-items: center;
	.flag-icon,
	.chat-icon,
	.mail-icon {
		height: 24px;
	}
}

.footer-mobile .footer-text-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	position: relative;
	p {
		font-size: 14px;
		line-height: 19px;
	}
}

