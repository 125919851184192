@import 'src/assets/sass/variables.scss';
@import 'src/assets/sass/mixins';

.keyboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
  margin-top: 32px;
}

.gameWon {
  color: white;
	font-size: bold;


}

.gameLost {
  color : white;
	font-size: bold;

}

.row {
  display: flex;
  margin-bottom: 0.5rem;
}

.key {
  width: 40px;
  background-color: transparent;
  border-radius: 5px;
  color: var(--Button-Secondary-default-label, #1FF808);


  font-size: 1.2rem;
  font-weight: 500;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 0.5rem;
  margin: 0 0.2rem;

  height: 48px;
  padding: 12px;
  gap: 8px;
  border-radius: 5px;
  border: 1px solid var(--Button-Secondary-default-label, #1FF808)

}

.absent {
  background-color: var(--color-absent);
  color: #fff;
}

.present {
  background-color: var(--color-present);
  color: #fff;
}

.correct {
  background-color: var(--color-correct);
  color: #fff;
}

.action {
  color: var(--color-background);
  font-size: 0.9rem;
  width: auto;
}

@media screen and (max-width: 480px) {
  .keyboard {
    margin: 0;
  }

  .key {
    width: 30px;
    font-size: 1rem;
  }

  .action {
    width: auto;
    font-size: 0.7rem;
  }
}
