@import 'src/assets/sass/variables.scss';

.app-button {
	display: flex;
	justify-content: center;
	align-items: center;

	&__icon {
		margin-right: 5px;
	}

	&-generic-container {
		width: auto;
	}
}

.app-button-container-events-filter {
	margin-bottom: 8px;
	padding-right: 20px;
	width: 100%;
}
