$box-shadow: -5px 5px 0px 0px #000;
// ----------- CALCUATE OVERFLOW STYLING -----------
$length-app-card-content-padding: 32px;
$length-content-container-mobile-padding: 32px;
$length-content-container-tablet-padding: 20px;
// ----------- NEW COLORS FOR PRIDE ------------
$color-dark-purple: #52024a;
$color-purple: #8b3d83;
$color-light-purple: #c094bc;
$color-dark-red: #c3002f;
$color-red: #b94b65;
$color-light-red: #c999a5;
$color-dark-blue: #66a3ff;
$color-blue: #adceff;
$color-light-blue: #dae9ff;
$color-darkest-gray: #858585;
$color-dark-gray: #b3b5bd;
$color-gray: #d2d4da;
$color-light-gray: #f3f4f8;
$color-cyan: #1be1f2;
$color-cyan-light-1: #5feaf6;
$color-cyan-light-2: #8df0f9;
$color-coral-light-3: #ffdbdf;
$color-dark-blue-2: #121C49;
$primary-background-gradient: linear-gradient(91.03deg, rgba(0, 0, 0, 0.376) 2.56%, rgba(56, 56, 56, 0.344) 51.31%, rgba(0, 0, 0, 0.432) 103.21%);
$secondary-background-gradient: linear-gradient(90.6deg, rgba(255, 255, 255, 0.15) 20.99%, rgba(255, 255, 255, 0.075) 50%, rgba(255, 255, 255, 0.15) 80.25%);
$avatar-background-gradient: linear-gradient(.25turn, rgba(255, 255, 255, 0.42), rgba(255, 255, 255, 0.06));
$primary-border: 1px solid rgba(255, 255, 255, 0.3);
$dropdown-background: rgba(255, 255, 255, 0.15);
$text-shadow: 0px 0px 15px rgba(27, 225, 242, 0.8);
// ----------- NEW COLORS -----------
$color-slalom-blue: #0c62fb;
$color-cyan: #1be1f2;
$color-wrong-red: #ff0000;
$color-correct-green: #0b9d59;
$color-coral-red: #ff4d5f;
$color-light-red: #ff828f;
$color-chartreuse: #deff4d;
$color-chartruese-dark: #a4c800;
$color-light-black: #343434;
$color-bright-green: #bdffdd;
$color-light-coral-red: #ffc6ce;
$color-cream: #fbffed;
// ----------- OLD COLORS TO KEEP BUILD SUCCESSFUL TEMPORARILY -----------
$color-slalom-primary: rgba(222, 255, 77, 1);
$color-bg-light: #ebebeb;
$color-bg-border: #9c9c9c;
$color-black: #000;
$color-white: #fff;
$color-lilac: #eee9ff;
$color-yellow: $color-chartreuse;
$color-red: $color-coral-red;
$transparent-light-purple: rgba(255, 216, 185, 0.2);
$card-shadow-color: rgba(222, 255, 77, 0.6);
$card-shadow: 0px 0px 40px 0px $card-shadow-color;
$text-shadow: 0px 0px 10px $color-light-purple;
$card-bg: $color-cream;
// ----------- NEW COLORS FOR HACKTOBER 2023 ------------
// Colors
$color-dark-blue: #0B0617;
$color-blue: #0050AE;
$color-dark-gray: #D3D3D3;
$color-darkest-gray: #343434;
$color-white: #FFFFFF;
$color-off-white: #FEFAFF;
$color-red: #C3002F;
$color-green: #008900;
$color-app-button-primary-hover: $color-blue;
$color-teal: #2AE4FE;
$color-cyan: #1FF808;
$color-cyan-dark: #1FF808;
// Gradients
/*** Start: Hacktober 2024 gradient colors ***/
$color-green-gradient-mid-vertical1: linear-gradient(180deg, rgba(9, 250, 88, 1), rgba(6, 160, 56, 1));
$color-green-gradient-dark-horizontal1: linear-gradient(90deg, rgba(11, 6, 23, 1), rgba(25, 85, 61, 1));
$color-green-gradient-dark-vertical1: linear-gradient(180deg, #0B0617 0%, #19553D 100%);
$color-green-gradient-dark-vertical2: linear-gradient(180deg, #19553D 0%, #0B0617 100%);
$color-green-gradient-light-horizontal1: linear-gradient(90deg, #1FF808 100%, #E9FFE1 100%);
$color-green-gradient-light-horizontal2: linear-gradient(180deg, #1FF808 100%, #E9FFE1 100%);
/*** End: Hacktober 2024 gradient colors ***/

$color-teal-gradient-vertical: linear-gradient(180deg, #AFFFD2 0%, #63C7D4 136.93%);
$color-teal-gradient-horizontal: linear-gradient(269.4deg, #AFFFD2 16.42%, #63C7D4 84%);
$color-teal-gradient-horizontal-reverse: linear-gradient(269.4deg, #63C7D4 16.42%, #AFFFD2 84%);
$color-blue-gradient: linear-gradient(180deg, #2AE4FE 0%, rgba(255, 255, 255, 0) 135.39%);
$color-correct-answer: linear-gradient(180deg, #98FDC4 0%, #73F287 136.93%);
$color-wrong-answer: linear-gradient(180deg, #FC8E99 0%, #FD7886 100%);
$color-gray-gradient-radial: radial-gradient(127.19% 120.51% at 50% 0%, rgba(14, 25, 32, 0) 54.32%, #0E1920 79.01%);
$color-gray-gradient-background: linear-gradient(180deg, #0E1920 0%, rgba(0, 0, 0, 0) 52.57%, rgba(14, 25, 32, 0.5) 99.48%);
// Shadows
$color-teal-glow: #1BE1F2;
$color-glow: #E8E6F1;
$box-shadow-teal-glow: 0px 0px 15px 0px $color-teal-glow;
$box-shadow-glow: 0px 0px 15px 0px $color-glow;
$text-shadow: 0px 0px 15px $color-glow;

// Shadows Hacktober 2024
$color-green-glow: #09FA58;
$box-shadow-green-glow: 0px 0px 16px 0px rgba(9, 250, 88, 0.8);
$box-shadow-black-glow: 0px 0px 16px 0px $color-black;

$color-slalom-primary: #0c62fb;
$color-slalom-primary-dark: darken(#0c62fb, 10%);
$color-bg-light: #ebebeb;
$color-bg-border: #9c9c9c;
$color-black: #000000;
$color-white: #ffffff;
$color-gray: #7c7c7c;
$color-light-gray: lighten($color-gray, 20%);
$color-dark-gray: darken($color-gray, 20%);

$color-text-shadow: $color-gray;

$color-success: #135b09;
$color-error: #7e0b17;

// breakpoints
$breakpoint-sm: 375px; // breakpoint for mobile
$breakpoint-md: 768px; // breakpoint for tablet
$breakpoint-lg: 1440px;
$breakpoint-xl: 1920px;

// page breakpoints
$breakpoint-events-grid-container: 969px;

$color-primary: #6139a3;
$color-text-secondary: #005c40;
$color-border-secondary: #70c5b5;
$color-card-secondary: #d7fff8;
$color-input-error-bg: #ffa6af;
$color-input-error-border: #e95a5a;
$color-input-error-text: $color-error;
$color-input-success-bg: #d3facc;
$color-input-success-border: #5cd448;
$color-input-success-text: $color-success;
$color-error-bg: #7E0B17;

/*********  New colors for Zenith Hacktober 2024   *********/
// Theme Colors
$color-accent-1: #E3B1EA;
$color-secondary-500: #1B8A57;
$color-secondary-400: #1BB417;
$color-accent-2: #A7EA28;
$color-accent-3: #6EFFFF;
$color-primary-500: #19553D;
$color-primary-700: #0B0617;
$color-secondary-300: #1FF808;
$color-secondary-200: #E9FFE1;

// neutrals
$color-white-15: $color-white;
$color-grey-50: #F1F1F1;
$color-grey-200: #D3D3D3;
$color-grey-400: #AFAFAF;
$color-grey-600: #7C7C7C;
$color-grey-800: $color-light-black;
$color-grey-900: #202020;

// system
$color-danger-red-500: $color-input-error-border;
$color-warning-500: #FFE601;
$color-success-green-100: $color-secondary-200;
$color-danger-red-100: $color-input-error-bg;
$color-success-green-900: $color-primary-500;
$color-danger-red-900: $color-error;
$color-success-green-500: $color-secondary-300;

// surface
$color-surface-primary: $color-primary-700;
$color-surface-secondary: $color-primary-500;
$color-surface-inverted: $color-white;
$color-surface-globalbg: $color-primary-700;

// text
$color-text-primary: $color-white;
$color-text-secondary: $color-secondary-300;
$color-text-inverted-primary: $color-black;
$color-text-tertiary: $color-dark-gray;
$color-text-inverted-secondary: $color-primary-500;

//gradients 
$color-button-gradient: linear-gradient(180deg,
    #09FA58 0%,
    #06A038 100%);
$color-speaker-gradient: linear-gradient(180deg,
    $color-primary-500 0%,
    $color-primary-700 100%);
$color-background-gradient: linear-gradient(0deg,
    $color-primary-500,
    $color-primary-700);

$box-shadow-green-glow: 0px 0px 15px 0px $color-secondary-300;

// button primary
$color-button-primary-default-label: $color-primary-700;
$color-button-primary-disabled: $color-grey-400;
$color-button-primary: $color-button-gradient;
$color-button-primary-hover: $color-secondary-300;
$color-button-primary-pressed: $color-secondary-400;
$button-shadow: 0px 0px 16px 0px lighten(#09FA58, 20%);

// button secondary
$color-button-secondary: $color-primary-700;
$color-button-secondary-default-label: $color-secondary-300;
$color-button-secondary-hover: $color-primary-500;
$color-button-secondary-disabled: $color-grey-600;
$color-button-secondary-pressed: $color-primary-500;
$color-button-secondary-disabled-label: $color-grey-200;

// border
$color-border-primary: $color-secondary-300;
$color-border-secondary: $color-secondary-300;
$color-border-inverted: $color-primary-700;

// form
$color-form-correct-bg: $color-primary-500;
$color-form-incorrect-bg: $color-error;
$color-form-correct-label: $color-white;
$color-form-incorrect-label: $color-white;
$color-form-default-bg: $color-primary-700;
$color-form-default-border: $color-secondary-400;
$color-form-hover-border: $color-secondary-300;
$color-form-focus-border: $color-secondary-300;
$color-form-correct-border: $color-secondary-300;
$color-form-incorrect-border: $color-input-error-border;
$color-form-disabled-bg: $color-light-black;
$color-form-disabled-border: $color-dark-gray;

// form button
$color-button-text-default-label: $color-secondary-300;
$color-button-text-pressed-label: $color-secondary-400;
$color-button-text-hover-label: $color-secondary-400;
$color-button-text-disabled-label: $color-gray;

// form button nav
$color-button-nav-default-label: $color-white;
$color-button-nav-hover-label: $color-secondary-300;
$color-button-nav-selected-label: $color-secondary-300;