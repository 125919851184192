@font-face {
	font-family: Slalom Sans;
	src: url('./assets/fonts/SlalomSans/SlalomSans-Regular.woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: Slalom Sans;
	src: url('./assets/fonts/SlalomSans/SlalomSans-Black.woff');
	font-weight: bolder;
	font-style: normal;
}

@font-face {
	font-family: Slalom Sans;
	src: url('./assets/fonts/SlalomSans/SlalomSans-BlackItalic.woff');
	font-weight: bolder;
	font-style: normal;
	color: black;
}

@font-face {
	font-family: Slalom Sans;
	src: url('./assets/fonts/SlalomSans/SlalomSans-Italic.woff');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: Slalom Sans;
	src: url('./assets/fonts/SlalomSans/SlalomSans-BoldItalic.woff');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: Slalom Sans;
	src: url('./assets/fonts/SlalomSans/SlalomSans-Bold.woff');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: Slalom Sans;
	src: url('./assets/fonts/SlalomSans/SlalomSans-Light.woff');
	font-weight: lighter;
	font-style: normal;
}

@font-face {
	font-family: Slalom Sans;
	src: url('./assets/fonts/SlalomSans/SlalomSans-LightItalic.woff');
	font-weight: lighter;
	font-style: italic;
}

@font-face {
	font-family: Changa;
	src: url('./assets/fonts//ChangaFonts/Changa-Regular.ttf');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: Changa;
	src: url('./assets/fonts//ChangaFonts/Changa-SemiBold.ttf');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: Changa;
	src: url('./assets/fonts//ChangaFonts/Changa-Bold.ttf');
	font-weight: bolder;
	font-style: normal;
}

@font-face {
	font-family: Changa;
	src: url('./assets/fonts//ChangaFonts/Changa-Light.ttf');
	font-weight: light;
	font-style: normal;
}

@font-face {
	font-family: Changa;
	src: url('./assets/fonts//ChangaFonts/Changa-ExtraLight.ttf');
	font-weight: lighter;
	font-style: normal;
}

@font-face {
	font-family: SpectralSC;
	src: url('./assets/fonts//SpectralSC/SpectralSC-Regular.ttf');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: SpectralSC;
	src: url('./assets/fonts//SpectralSC/SpectralSC-SemiBold.ttf');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: SpectralSC;
	src: url('./assets/fonts//SpectralSC/SpectralSC-Bold.ttf');
	font-weight: bolder;
	font-style: normal;
}

@font-face {
	font-family: SpectralSC;
	src: url('./assets/fonts//SpectralSC/SpectralSC-Light.ttf');
	font-weight: light;
	font-style: normal;
}

@font-face {
	font-family: SpectralSC;
	src: url('./assets/fonts//SpectralSC/SpectralSC-ExtraLight.ttf');
	font-weight: lighter;
	font-style: normal;
}

.standalone-page-wrapper {
	margin-top: -80px;
}
