.home-page-desktop,
.home-page-tablet {
	background-size: 100% 630px;
}

.home-page-mobile {
	background-size: 100% 370px;
}

.home-page-container {
    .page-wrapper {
        display: flex;
        justify-content: center;
        .page-banner-img__text {
            top: 290px;
        }
        &__content-container{
            width: 100%;
        }
        .page-wrapper__content-container {
            flex-direction: column;
        }
    }

    .page-wrapper-content-tablet {
		.home-page-top {
			img {
				width: 100%;
			}
            .page-banner-img__text {
                top: 290px;
            }
		}
	}

    .page-wrapper-content-mobile {
		.home-page-top {
			img {
				width: 100%;
			}
            .page-banner-img__text {
                top: 250px;
            }
		}
	}

}

.code-hunt-wrapper {
    max-width: 1320px;
    margin: auto;
}