.mobile-message {
	color: white;
	margin: 30px;
}

.points-disclaimer {
	margin: auto;
	padding-top: 5rem;
	width: 700px;
	text-align: center;
}
