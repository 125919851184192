@import "src/assets/sass/variables.scss";
@import "src/assets/sass/mixins";

// Challenge Header
.challenge-header {
  .app-card-content {
    @include card();
    display: flex;
    flex-direction: row;
  }
}

.challenge-points {
  white-space: nowrap;
  margin-right: 32px;
  font-size: 24px;
  font-weight: 700;
  color: $color-secondary-300;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  > img {
    margin-right: 8px;
  }

  &.-left-align {
    align-items: flex-start;
  }
}

// Challenge Board
.challenge-board {
  .app-card-content {
    @include card();
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 56px 0;
    position: relative;

    .board-offset {
      position: relative;
      right: 57px;
    }
  }
}

.challenges-unavailable {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 92, 64, 0.75);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 1.5;
  border-radius: 10px;
  font-family: Slalom Sans;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: $color-white;
}

// Challenge Row
.challenge-row {
  display: flex;
}

.offset {
  position: relative;
  left: 114px;
}

// Challenge Tile
.challenge-tile {
  margin: -50px -40px;
  position: relative;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.hexagon {
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  background: transparent;
  width: calc(100% - 100px);
  height: calc(100% - 60px);
  position: absolute;
  left: 50px;
  top: 28px;
  z-index: 1;

  &.available.incomplete:hover {
    cursor: pointer;
    background: rgba(0, 18, 51, 0.5);
  }
}

// Challenge View
.challenge-view {
  .app-card-content {
    @include card();
    gap: 32px;
  }

  p {
    margin: 0;
    font-family: Slalom Sans;
    color: $color-black;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px !important;
  }
}

.answers-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

.answer-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  border: 1px solid $color-secondary-400;
  border-radius: 10px;
  background: $color-primary-700;
  text-align: start;
  cursor: pointer;

  &.selected {
    background-image: $color-green-gradient-dark-horizontal1;
		background-color: $color-primary-700;
		background-position: right;
		background-size: cover;

    p {
      color: $color-secondary-300;
    }
  }

  &:hover {
    p {
      color: $color-secondary-300;
    }
  }

  &.correct {
    background: $color-primary-500;
  }

  &.incorrect {
    border: 1px solid $color-input-error-border;
    background: $color-error;
  }

  > .answer-radio {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: 1px solid $color-secondary-400;
    height: 24px;
    width: 24px;
    min-width: 24px;
    border-radius: 50%;

    &.selected {
      border: 2px solid $color-secondary-300;
    }

    &.correct {
      border: 2px solid $color-secondary-300;
    }

    &.incorrect {
      border-color: $color-input-error-bg;
    }

    > .dot {
      height: 14px;
      width: 14px;
      border-radius: 50%;
      background-color: transparent;

      &.selected {
        background-color: $color-secondary-300;
      }

      &.correct {
        background-color: $color-secondary-300;
      }

      &.incorrect {
        background-color: $color-input-error-bg;
      }
    }
  }
}

.challenge-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;

  > p {
    font-size: 14px !important;
    line-height: 20px !important;
  }

  .button-container {
    display: flex;
    flex-direction: row;
    gap: 16px;

    button {
      width: 160px;
      padding: 12px 24px;
      border-radius: 8px;
      font-family: Slalom Sans;
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      cursor: pointer;
    }

    .back {
      background: $color-primary-700;
      border: 1px solid $color-secondary-300;
      color: $color-secondary-300;

      &:hover {
        background: $color-primary-500;
      }
    }
  }
}
