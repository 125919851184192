@import 'src/assets/sass/variables.scss';

.tooltip-container {
	position: relative;
	display: flex;
	height: auto;
	align-items: center;
	margin-left: 15px;
}

.tooltip-popup {
	display: flex;
	position: absolute;
	width: 242px;
	z-index: 10;
	padding: 24px 8px 24px 24px;
	color: $color-black;
	background-color: $color-white;
	border-radius: 10px;
	top: 0;
	left: 10px;
	transform: translate(-100%, 0%);
  
	@media screen and (max-width: 320px) {
	  position: fixed;
	  left: 50%;
	  top: 50%;
	  transform: translate(-50%, -50%);
	  margin-left: 0px;
	}
  
	&__close {
	  justify-self: flex-end;
	  text-align: right;
	  cursor: pointer;
	}
  
	&__content {
	  padding-top: 16px;
	}
  }

.tooltip-icon {
	cursor: pointer;
	border-radius: 20px;
	position: relative;
}