@import 'src/assets/sass/variables.scss';
@import 'src/assets/sass/mixins';

.errorpage-background-image {
  z-index: -1;
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
}

.error-btn-container-desktop {
  margin: 0px auto 80px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  position: absolute;
  top: 90%;
  width: 100%;
  gap: 16px;
}

.error-btn-container-tablet {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-position: 100%;
  gap: 16px;
}

.error-btn-container-mobile {
  margin: 0 0 60px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-position: 100%;
  gap: 16px;
}

.error-page {
  .app-button-secondary {
    @include longButtonTexts;
  }
  .app-button-primary {
    @include longButtonTexts;
  }
}

.error-page-container-large {
  margin: 0 60px;
  display: flex;
  flex-direction: column;
}

.error-page-container-tablet {
  position: absolute;
  left: 0;
  bottom: 50px;
  right: 0;
}

.error-page-container-mobile {
  margin: 0 40px;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
}

.play-pause {
  position: absolute;
  margin: auto;
  top: 10px;
  left: 80%;
}
