@import "src/assets/sass/variables";
@import "src/assets/sass/mixins";

.kudos-button {
  @include buttonPrimary();
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  color: $color-black !important;
  font-weight: bold;
  width: 149px;
  height: 48px;

  // Thumbs Up
  .app-button__icon {
    margin-right: 1px;
    width: 17%;
    height: auto;
  }

  // Kudos Given State (Static)
  &--submitted,
  &--submitted:hover,
  &--submitted:disabled {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: $color-black !important;
    padding: 1rem !important;

    // Kudos Given
    .app-button__icon {
      margin-bottom: 1.5px;
      width: 18%;
    }
  }
}

.app-button__icon {
  margin-right: 10px;
}

.link-button-container {
  display: flex;
}

.full-width {
  width: 100%;
}

.add-to-calendar-button {
  justify-content: center;
}

.join-now-button {
  @include buttonPrimary();
  display: flex;
  justify-content: center;
  align-items: left;
}