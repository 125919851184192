@import 'src/assets/sass/variables.scss';
@import 'src/assets/sass/mixins.scss';

.navigation-bar-container-desktop {
	width: 100vw; //100vw;
	height: 80px; //80px;
	z-index: 100; //100;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	position: absolute;
	transform-style: preserve-3d;
	padding-left: 15px;
	background: $color-primary-700;
	align-items: center;
}

.navigation-bar-container-tablet,
.navigation-bar-container-mobile {
	width: 100vw; //100vw;
	height: 80px; //80px;
	z-index: 100; //100;
	display: flex;
	flex-direction: row;
	position: absolute;
	transform-style: preserve-3d;
	margin-bottom: -80px;
	background-color: $color-primary-700;
}

.navigation-bar-container-mobile {
	background-color: $color-primary-700;
	padding-right: 10px;
}

.nav-container {
	display: flex;
}

.nav-links {
	margin: 14px 111px;
	padding: 12px 6px;
	width: 370px;
	height: 52px;
	background: #FBFFED;
	border: 1px solid #000000;
	border-radius: 100px;

	.nav-link.active {
		border: 1px solid $color-black;
		border-radius: 100px;
	}
}

.nav {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.nav-link {
	color: $color-black;
	align-self: center;
	position: relative;
	display: flex;
	align-items: center;
	text-decoration: none;
	font-weight: normal;
	width: 80px;
}

.nav-link-open {
	font-weight: normal;
	color: $color-black;
	position: relative;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	padding: 0rem 1rem;
	text-align: center;
}

.nav-user-container-desktop {
	display: flex;
	gap: 16px;
}

@media screen and (max-width: 705px) {
	.nav-user-container {
		margin-left: 0;
	}
}

.nav-points {
	font-size: 16px;
	align-self: center;
	cursor: default;
	color: $color-white;
	font-weight: 700;
	display: block;
}

@media screen and (max-width: 705px) {
	.nav-points {
		display: none;
	}
}

.nav-user {
	margin: 15px;
	height: 50px;
	width: 50px;
	align-self: center;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-right: 16px;
}

.nav-user-container {
	display: flex;
	flex-direction: row;
	gap: 16px;
}

.user-container {
	display: flex;
	flex-direction: row;
}

.nav-user-img {
	border: 1px solid $color-blue-gradient;
	border-radius: 50%;
	background: $avatar-background-gradient;
}

/* HOVER STATE */
.nav-link-text:before {
	content: '';
	position: absolute;
	visibility: hidden;
	height: 2px;
	width: 0;
	right: 50%;
	bottom: 7px;
	transition: all 0.2s ease-in-out;
}

.nav-link:hover {
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.nav-link.active>.nav-link-text {
	position: relative;
}

.nav-link.active>.nav-link-text:before {
	right: 0;
	bottom: -5px;
	width: 100%;
	visibility: visible;
}

.nav-link.active {
	color: $color-black;
	font-weight: bold;
}

/* OPEN NAV */
.nav-link-open:before {
	content: '';
	position: absolute;
	height: 1px;
	bottom: 0;
	background-color: $color-secondary-300;
	width: 90%;
}

.nav-link-open:hover:before {
	visibility: visible;
}

.nav-link-open.active {
	font-weight: bold;
	color: $color-secondary-300;
}

.nav-link-open:hover.active {
	border-radius: 25px;
}

.nav-link-open:hover {
	color: $color-secondary-300;
	border-radius: 25px;
}

.nav-link-open {
	color: $color-white;
}

/* Hamburger Nav */

.nav-hamburger {
	position: relative;
}

.nav-hamburger span {
	z-index: 10;
	cursor: pointer;
	align-self: center;
	right: 200px;
	color: $color-white;
}

.nav-menu {
	position: fixed;
	top: -280px;
	background: $color-primary-700;
	right: 0;
	width: 100%;
	overflow: hidden;
	max-width: 100vw;
	z-index: 9;
	margin: 0;
	padding: 0;
	height: 258px;
	transition: all 0.2s ease-in-out;
	transform: translateZ(-10px);
	font-size: 16px;
}

.nav-menu.nav-show {
	top: 80px;
	width: 100%;
}

.nav-show-mobile {
	width: 100%;
	top: 80px;
}

/* Hamburger Menu Icon */

#nav-icon {
	width: 40px;
	height: 30px;
	position: relative;
	margin: 20px auto;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: 0.5s ease-in-out;
	-moz-transition: 0.5s ease-in-out;
	-o-transition: 0.5s ease-in-out;
	transition: 0.5s ease-in-out;
	cursor: pointer;
	z-index: 10;
	margin-right: 1rem;
}

@media screen and (max-width: 705px) {
	#nav-icon {
		margin-right: 0;
	}
}

#nav-icon span {
	display: block;
	position: absolute;
	height: 4px;
	width: 100%;
	background: $color-white;
	border-radius: 9px;
	opacity: 1;
	left: 0;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: 0.25s ease-in-out;
	-moz-transition: 0.25s ease-in-out;
	-o-transition: 0.25s ease-in-out;
	transition: 0.25s ease-in-out;
}

#nav-icon span:nth-child(1) {
	top: 6px;
	-webkit-transform-origin: left center;
	-moz-transform-origin: left center;
	-o-transform-origin: left center;
	transform-origin: left center;
}

#nav-icon span:nth-child(2) {
	top: 18px;
	-webkit-transform-origin: left center;
	-moz-transform-origin: left center;
	-o-transform-origin: left center;
	transform-origin: left center;
}

#nav-icon span:nth-child(3) {
	top: 30px;
	-webkit-transform-origin: left center;
	-moz-transform-origin: left center;
	-o-transform-origin: left center;
	transform-origin: left center;
}

#nav-icon.open span:nth-child(1) {
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
	top: 4px;
	left: 5px;
}

#nav-icon.open span:nth-child(2) {
	width: 0%;
	opacity: 0;
}

#nav-icon.open span:nth-child(3) {
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	transform: rotate(-45deg);
	top: 32px;
	left: 5px;
}

.mobile-nav-rankings {
	display: flex;
	height: 50px;
	padding: 1rem 1rem;
	font-weight: 700;
	justify-content: space-between;
	width: 93%;
}

.nav-right-container {
	display: flex;
	align-items: center;
	gap: 16px;
}

.nav-options {
	cursor: pointer;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 16px;
	height: 52px;
	text-decoration: none;
	font-size: 16px;

	&:hover {
		color: $color-white;
		text-shadow: 0px 0px 15px $text-shadow;
	}
}

.nav-option {
	@include buttonNav;
}

.selected-nav-option {
	background-color: $color-yellow;
	border: 1px solid $color-bg-border;
	color: $color-dark-purple;
}

.nav-option.unselected-nav-option {
	color: $color-yellow;
	background: none;
}