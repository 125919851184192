@import 'src/assets/sass/variables.scss';
@import 'src/assets/sass/mixins';

.team-profile-tile-container {
	flex-basis: 20%;
	min-width: 250px;
	max-width: 250px;
	display: flex;
}

.about-container {
	.about-header {
		margin: 0;
	}

	.about-list-desktop-component {
		justify-content: center;
	}

	.about-list-tablet-component {
		width: calc(100% + 30px);
		padding-right: calc(5px + 1vw);
		.team-profile-tile-container {
			&:last-child {
				margin-right: auto;
			}
			&:first-child {
				margin-left: auto;
			}
		}
	}

	.about-list-mobile-component {
		width: calc(100% + 30px);
		padding-right: calc(5px + 1vw);
		.team-profile-tile-container {
			&:last-child {
				margin-right: auto;
			}
			&:first-child {
				margin-left: auto;
			}
		}
	}

	::-webkit-scrollbar-thumb {
		border: 4px solid $color-white;
	}
}

.team-profile-front-tile-image-container {
	color: FFFFFF;
	width: 100%;
	height: 200px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}

.team-profile-front-tile-info-container {
	width: 100%;
	padding: 0 8px;
	height: 120px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	text-align: center;
	background: $color-dark-blue;
	padding-top: 20px;

	&.mobile {
		height: 113px;
	}
}

.team-profile-name-container {
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 20px;
	color: $color-white;
}

.team-profile-role-container {
	font-size: 14px;
	line-height: 20px;
	font-weight: 400;
	color: $color-white;
	width: auto;
}

.about-info-container {
	width: 100%;
	display: flex;
	align-items: flex-start;
	flex-direction: column;
}

.about-list .app-card-content {
	box-shadow: none !important;
	padding: 0 !important;
}

.team-profile-back-tile-container {
	width: 250px;
	height: 80px;
	font-weight: 400, regular;
	font-size: 16px;
	line-height: 19px;
	margin-top: 140px;
	position: absolute;
	text-align: center;
	justify-content: center;
	border-bottom-left-radius: 16px;
	border-bottom-right-radius: 16px;
}

.team-profile-quote-container {
	margin-top: 20px;
	margin-left: 5px;
}

@media screen and (max-width: 1182px) {
	.profile-tile-margin {
		margin-right: 16px;
	}
}
