@import 'src/assets/sass/variables.scss';
@import 'src/assets/sass/mixins';

.welcome-modal {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba($color: $color-black, $alpha: 0.5);
  transition: 0.3s ease-in-out;

  &.active {
    opacity: 1;
    visibility: visible;
  }
}

.welcome-modal-container-desktop{
  margin: 5% auto;
  background: #0B0617;
  border: 3px solid #1BB417;
	border-image: #1BB417;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  width: 60%;
}
.welcome-modal-container-tablet{
  margin: 5% auto;
  background: #0B0617;
  border: 3px solid #1BB417;
	border-image: #1BB417;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  width: 90vw;
}
.welcome-modal-container-mobile{
  margin: 5% auto;
  background: #0B0617;
  border: 3px solid #1BB417;
	border-image: #1BB417;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  width: 90vw;
}


.welcome-title {
  color: $color-white;
  width: 10px;
  margin-top: 20px;
  white-space: nowrap; /* Prevent line breaks */
}
@media (min-width: 1024px) {
  .welcome-title.desktop {
    font-size: 32px;
  }
}
@media (max-width: 1023px) and (min-width: 768px) {
  .welcome-title.tablet {
    font-size: 24px; // Set the desired font size for tablets
  }
}
/* Mobile */
@media (max-width: 767px) {
  .welcome-title.mobile {
    font-size: 18px; // Set the desired font size for mobiles
  }
}

.card-container {
  width: 100%;
}

.welcome-modal-body {
  display: flex;
  flex-direction: column;
  gap: 32px;
  position: relative;
}

.welcome-modal-section {
  display: flex;
  gap: 10px;

  &__image {
    margin: auto 0;
    padding-left: 40px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    color: white;
    margin-left: 24px;

    &__title {
      font-weight: 700;
      font-size: 18px;
    }

    &__body {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      padding-right: 40px;
    }
  }
}

@media (max-width: 320px) {
  .welcome-modal-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    // justify-content: flex-start;
    // padding: 0 16px;
    &__image {
      // margin-right: 10px;
      padding-left: 20px;
    }

    &__content {
      &__title {
        font-weight: 700;
        font-size: 18px;
        margin-right: 30px; /* Ensure no extra space to the right of the title */
        padding-right: 20px;
      }

      &__body {
        display: none; /* Hide body on smaller screens */
      }
    }
  }
}


.app-button-welcome {
  @include buttonPrimary();
  height: 48px;
  width: 180px;
  cursor: pointer;
	text-transform: uppercase;
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: 14px;
}

.welcome-header-icon {
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../assets/images/graphic-left.svg");
  background-size: contain;

  &.flip {
    transform: scaleX(-1);
  }

  &.mobile {
    width: 30px;
    height: 30px;
    margin: 0 15px 0 15px;

  }

  &.tablet {
    width: 25vw;
    height: 80%;
  }

  &.desktop {
    width: 25vw;
    height: 80%;
  }
}

.welcome-top-right-img-mobile {
  display: none;
}

.welcome-top-right-img-desktop,
.welcome-top-right-img-tablet {
  position: absolute;
  top: 0;
  right: 0;
  width: 172px;
  height: 120px;
}
