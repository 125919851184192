@import "src/assets/sass/_variables";
@import "src/assets/sass/_mixins";

input::placeholder {
  font-family: "Slalom Sans";
  font-weight: 400;
  font-size: 16px;
  color: $color-black;
  opacity: 1;
  letter-spacing: 0;
}

.claim-points {
	@include card();
	display: flex;

	@media screen and (max-width: $breakpoint-md) {
		flex-direction: column-reverse;
		gap: 0px;
  }

  &__header {
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 16px;
  }

  &__description {
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 32px;
  }
}

.claim-points-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 24px;
}

.claim-points-image-container {
  overflow: hidden;
  width: 100%;
  max-width: 575px;
  position: relative;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;

  @media screen and (max-width: $breakpoint-md) {
    max-width: 768px;
    height: 200px;
	border-top-left-radius: 10px;
  	border-bottom-right-radius: 0px;
  }
}

.claim-points-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center; /* Centers the image */
  position: absolute; /* Positions the image within the container */
  top: 0px;
  left: 0;
}

.claim-points-description-container {
  display: flex;
  flex-direction: column;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
}

.claim-points-header-secondary {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

.claim-points-body-desktop {
  margin-top: 16px;
  margin-bottom: 30px;
}

.claim-points-body-tablet,
.claim-points-body-mobile {
  #claim-points-input-message {
    margin: 0px;
  }
}

.claim-points-description-container p {
  margin-top: 16px;
  padding-top: 16px;
  padding-bottom: 24px;
  line-height: 18px;
}

.description-container-content {
  flex-flow: wrap;
  margin-right: 24px;
  width: 30%;

  @media screen and (max-width: $breakpoint-md) {
    width: 100%;
  }
}

.claim-points-form {
  display: flex;
  width: 100%;
  align-items: flex-end;
  gap: 16px;

  @media screen and (max-width: $breakpoint-md) {
    flex-flow: row wrap;
  }
}

.claim-points-enter-code-container {
  display: flex;
  flex-direction: column;
  color: white;
  font-size: 16px;
  width: 100%;

  @media screen and (max-width: $breakpoint-md) {
    display: flex;
    flex-direction: column;
    color: white;
    font-size: 16px;
    width: 100%;
  }
}

.claim-points-input {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  gap: 16px;
  height: 48px;
  background: $color-primary-700;
  border: 1px solid $color-secondary-400;
  font-size: 20px;
  text-align: left;
  letter-spacing: 32px;
  border-radius: 10px;
  color: $color-white;

}

.claim-points-input::placeholder {
  color: $color-white;
}

.claim-points-input:focus {
  outline: none;
}

.claim-points-input-error {
  color: $color-white;
  height: 51px;
  padding: 12px 16px;
  border-color: $color-form-incorrect-border;
  background-color: $color-form-incorrect-bg;
}

.claim-points-input-success {
  color: $color-white;
  height: 51px;
  padding: 12px 16px;
  border-color: $color-form-correct-border;
  background-color: $color-form-correct-bg;
}

.claim-points-input-message {
  color: $color-white;
  font-size: 16px;
  line-height: 24px;
  margin-top: 8px;
  height: 24px;
}

.claim-points-input-message-error {
  color: $color-white;
  font-size: 16px;
  line-height: 24px;
  margin-top: 8px;
  height: 24px;
}

.claim-points-input-message-success {
  color: $color-white;
  font-size: 16px;
  line-height: 24px;
  margin-top: 8px;
  height: 24px;
}

.claim-points-submit-container {
  margin-bottom: auto;

  @media screen and (max-width: $breakpoint-md) {
    text-align: center;
    width: 100%;
    margin: auto;
  }
}

.claim-points-button-message-desktop {
  margin-left: 32px;
  margin-top: 12px;
  margin-bottom: 12px;
  height: 20px;
  font-size: 14px;
  line-height: 19px;
  max-width: 130px;
}

.claim-points-button-message-tablet,
.claim-points-button-message-mobile {
  margin-top: 12px;
  text-align: center;
  font-size: 14px;
  line-height: 19px;
}

.claim-points-button-container {
  width: 100%;
  margin: 0;

  display: flex;
  flex-direction: column;
  color: black;
  height: 50px;

  @media screen and (max-width: $breakpoint-md) {
    display: flex;
    justify-content: center;
  }
}