@import 'src/assets/sass/variables.scss';
@import 'src/assets/sass/mixins';

html,
body,
#root,
.App {
	margin: 0 !important;
	height: 100%;
	font-family: Slalom Sans !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

h1,
h2,
h3 {
	font-family: Slalom Sans;
}

a {
	color: $color-secondary-300;
	font-weight: bold;

	&.hoverUnderline {
		&:hover {
			text-decoration: underline;
		}
	}

	&:hover {
		color: $color-secondary-400;
	}
}

body ::-webkit-scrollbar {
	height: 5px;
}

body ::-webkit-scrollbar-thumb {
	background: #ffffff;
	width: 160px;
}

p {
	font-size: 16px;
}

.bug-banner {
	background-color: #dd7d25;
	height: 114px;
	margin: 0 80px;
	font-size: 16px;
}

.title-text {
	font-weight: bold;
	display: flex;
	align-items: center;
}

.title-text-large {
	font-size: 32px;
	line-height: 38px;
}

.title-text-mobile {
	font-size: 18px;
	line-height: 22px;
}

.page-large {
	background-size: 100% 630px;
}

.page-mobile {
	background-size: 100% 370px;
}

.page-title {
	position: absolute;
	right: 0;
	float: right;
	margin-top: 2em;
	width: 15em;
	color: white;
	font-size: 60px;
	font-weight: bold;
	letter-spacing: 0.3em;
	line-height: 90px;
}

.section-container {
	max-width: 1320px;
	margin: auto;
}

@media screen and (max-width: 1183px) {
	.section-container {
		width: 85%;
	}
}

.section-title {
	@include sectionHeader();
	display: flex;
	justify-content: center;
	z-index: 1;
	flex: none;
	order: 0;
	flex-grow: 1;

	&.small {
		font-size: 32px;
	}
}

@media screen and (max-width: 1183px) {
	.section-title {
		font-size: 36px;
		line-height: 42px;
		white-space: nowrap;
	}
}

@media screen and (max-width: 705px) {
	.section-title {
		font-size: 24px;
		line-height: 29px;
		white-space: normal;
		flex-shrink: 1;

	}

	.app-button-row {
		flex-direction: row;
	}
}

.app-button-row {
	display: flex;
	width: 100%;
	justify-content: space-between;
	flex-wrap: wrap;
}

@media screen and (max-width: 705px) {
	.app-button-row {
		flex-direction: row;
	}
}

.app-button-container {
	width: auto;
}

.app-button {
	@include button();
}

.app-button:focus {
	outline: none;
}

.app-button-primary {
	@include buttonPrimary();
	height: 51px;
	width: 130px;
}

.app-button-secondary {
	@include buttonSecondary();
	height: 51px;
	width: 130px;
}

.disabled {
	background: #b3b5bd;
	color: black;
}

.app-card-display {
	display: flex;
	border-radius: 20px;
	background: $card-bg;
	backdrop-filter: blur(40px);
	-webkit-backdrop-filter: blur(40px);
}

.flex-container {
	width: 100%;
	display: flex;
}

.flexible {
	flex-grow: 1;
}

.label-highlighted {
	@include labelHighlighted();
}

.app-button-text {
	background: none;
	color: $color-white;
	font-family: "Slalom Sans";
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	text-align: center;
	line-height: 19px;
	padding: 12px 24px 12px 24px;

	&:disabled {
		color: $color-gray;
		cursor: not-allowed;

		&:hover {
			cursor: not-allowed;
		}
	}

	&:hover {
		color: #AFFFD2;
		cursor: pointer;
	}
}