@import 'src/assets/sass/variables.scss';
@import 'src/assets/sass/mixins';

.top-leader-card {
	font-family: 'Slalom Sans';
	display: flex;
	position: relative;
	background: $color-green-gradient-dark-vertical2;
	color: $color-white;
	border: 2px solid $color-secondary-400;
	border-radius: 4px;
	box-shadow: 0px 0px 16px 0px #09FA58CC;
}

.top-leader-content-desktop {
	padding: 8px 12px;
	width: 100%;
	display: flex;
	flex-direction: column;
	min-height: 199px;
	z-index: 1;
	gap: 4px;
	&.office-leaderboard {
		gap: 32px;
	}
}

.top-leader-content-tablet,
.top-leader-content-mobile {
	padding: 8px 12px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	z-index: 1;
	gap: 4px;
	&.office-leaderboard {
		gap: 32px;
	}
}

.top-leader-position {
	margin: 0;
	font-size: 14px;
	line-height: 21px;
	letter-spacing: 0.02em;
}

.top-leader-avatar {
	font-size: 14px;
	width: 40px;
	height: 40px;
	border-radius: 50%;
}

.top-leader-stat-h2 {
	margin: 0;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
}

.top-leader-stat-h3 {
	margin: 0;
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
}

.top-leader-stat-h4 {
	margin: 0;
	.points-card {
		font-size: 14px;
		font-weight: 700;
		line-height: 21px;
		letter-spacing: 0.02em;
	}
}

.position-image {
	position: absolute;
	background-repeat: no-repeat;
	background-position: right;
	background-size: 360x 480x;
	top: 3px;
	right: 0;
	z-index: 0;
	width: 360px;
	height: 100%;
}

.position-0 {
	background-image: url('../../assets/images/hacktober-24-leaderboard/hacktober-24-leaderboard-gold.svg');
}

.position-1 {
	background-image: url('../../assets/images/hacktober-24-leaderboard/hacktober-24-leaderboard-silver.svg');
}

.position-2 {
	background-image: url('../../assets/images/hacktober-24-leaderboard/hacktober-24-leaderboard-bronze.svg');
}

@media screen and (min-width: 1182px) {
	.top-leader-card {
		max-width: 390px;
	}
}

@media screen and (min-width: 705px) {
	.top-leader-card {
		width: 32%;
	}
}

@media screen and (max-width: 1182px) {
	.top-leader-card {
		flex-direction: column;
		width: 31%;
		min-height: 299px;
		overflow: hidden;
		justify-content: space-between;
		text-align: center;
	}

	.top-leader-avatar {
		width: 50px;
		height: 50px;
	}

	.top-leader-content {
		align-items: center;
		padding-top: 40px;
	}

	.top-leader-rank {
		width: 40px;
		height: 40px;
		right: 10px;
		top: 10px;
	}

	.top-leader-stat-h2 {
		font-size: 18px;
	}

	.position-image {
		position: relative;
		background-repeat: no-repeat;
		background-size: 200px 91px;

		// Center tilted image
		background-position: center 0;
		background-position-x: 57%;

		width: 100%;
		height: 91px;
	}

	.position-0 {
		background-image: url('../../assets/images/hacktober-24-leaderboard/hacktober-24-leaderboard-gold-small.svg');
	}

	.position-1 {
		background-image: url('../../assets/images/hacktober-24-leaderboard/hacktober-24-leaderboard-silver-small.svg');
	}

	.position-2 {
		background-image: url('../../assets/images/hacktober-24-leaderboard/hacktober-24-leaderboard-bronze-small.svg');
	}

}

@media screen and (max-width: 705px) {
	.top-leader-card {
		max-width: 300px;
		min-width: 200px;
		margin-right: 16px;

		&:last-child {
			margin-right: 0;
		}
	}

	.top-leader-avatar {
		width: 40px;
		height: 40px;
	}

	.position-0 {
		background-image: url('../../assets/images/hacktober-24-leaderboard/hacktober-24-leaderboard-gold-small.svg');
	}

	.position-1 {
		background-image: url('../../assets/images/hacktober-24-leaderboard/hacktober-24-leaderboard-silver-small.svg');
	}

	.position-2 {
		background-image: url('../../assets/images/hacktober-24-leaderboard/hacktober-24-leaderboard-bronze-small.svg');
	}
}