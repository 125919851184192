@import 'src/assets/sass/variables';
@import 'src/assets/sass/mixins';

.event-card-container {
	flex-direction: column;
	justify-content: space-between;
	border-width: 2px;
	border-style: solid;

	.app-button {
		width: 100%;
	}

	.button-text:hover {
		text-decoration: underline;
	}

	.app-card-content {
		padding: 32px;
		justify-content: space-between;

		.event-content-details {
			color: $color-black;
		}
	}

	.kudos-button--submitted {
		width: 149px;
		border: 1px solid;
		border-color: $color-border-primary;
		background: $color-button-primary-pressed;
	}

	&.flipped {
		.app-card-content {
			background: linear-gradient(180deg, #AFFFD2 0%, #63C7D4 136.93%);

			.app-button-primary {
				color: $color-white;
				background: $color-dark-blue;
				border-color: $color-dark-blue;
			}

			.app-button-secondary {
				color: $color-dark-blue;
			}

			.kudos-button {
				display: none;
			}

			.kudos-button--submitted {
				display: none;
			}
		}



	}
}

.event-card-container-mobile {
	margin: 24px 0 0 0px;
}

@media screen and (max-width: 425px) {
	.event-card-container-mobile {
		width: 100%;
	}
}

.card-back {
	background: $color-light-purple !important;
	border: $color-black;
	border-radius: 20px;
}

/* Needed to override semantic-ui styles */
.ui.active.modal {
	display: flex !important;
	justify-content: center;
	box-shadow: none;
	max-width: 686px;
}

.ui.modal {
	background: none;
	border-radius: 10px;
}

.ui.modal>.icon:first-child+*,
.ui.modal> :first-child:not(.icon) {
	border-radius: 10px;
}

.ui.basic.modal>.close {
	top: 3.5rem;
	right: 6rem;
}

.ui.modal>.close {
	cursor: pointer;
	position: absolute;
	z-index: 1;
	opacity: 0.8;
	font-size: 2em;
	color: #fff;
	width: 2.25rem;
	height: 2.25rem;
	padding: 0.625rem 0 0 0;
}

.modals.dimmer .ui.scrolling.modal .visible.transition {
	margin: 1rem auto;
	display: flex;
	justify-content: center;
}

.event-weekday {
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
	color: $color-black;
}

.event-date {
	@include fontStandard();
	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
	margin-top: 16px;
	margin-bottom: 24px;
}

.event-time {
	font-size: 16px;
	font-weight: 300;
	line-height: 20px;
	color: $color-black;
}

.event-details {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: $color-white;
  justify-items: left;

  &__top-row {
    display: flex;
    gap: 16px;
    margin-bottom: 24px;
  }

  &__second-row {
    display: flex;
    gap: 36px;
  }

  &__date {
    display: flex;
    flex-direction: column;
    font-family: Slalom Sans;
    font-size: 24px;
    font-weight: 400;
    line-height: 28.8px;
    text-align: center;
    justify-content: center;
    gap: 4px;
  }

  &__name {
    display: flex;
    font-family: Slalom Sans;
    font-size: 24px;
    font-weight: 700;
    line-height: 28.8px;
    text-align: left;
    align-items: center;
  }

  &__location {
    display: flex;
    gap: 12px;
	margin-bottom: 16px;
	display: flex;
  	align-items: center;

    &__market {
      line-height: 24px;
    }
  }

  &__speakers-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 24px;
    margin-bottom: 24px;
	color: $color-black;
  }

  &__speaker-container {
	display: flex;
    flex-direction: column;
	color: white;
	font-weight: bold;
    background: $color-speaker-gradient;
	border-width: 2px;
	border-style: solid;
	border-color:$color-secondary-400;
	border-radius: 10px;
    gap: 8px;
    text-align: center;
    padding: 16px;

    &__market {
      font-weight: 400;
    }

		& > .link {
			color: white;

			&:hover {
				color: $color-secondary-300;
			}
		}
  }
}

.event-details-expanded {
	height: 55%;
	overflow-y: auto;
	padding-right: 32px;
	margin-bottom: 30px;
	scrollbar-color: white transparent;
}

.event-card-title {
	font-size: 25px;
	font-weight: 900;
	margin-bottom: 24px;
	padding-bottom: 8px;
	line-height: 28px;
	color: $color-black;
	overflow: hidden;
	color: $color-white;
}

.event-detail-title {
	font-size: 25px;
	font-weight: 900;
	line-height: 32px;
	max-width: 350px;
}

.event-points-container {
	display: flex;
	font-family: Slalom Sans;
	width: 165px;
	align-items: center;
}

.event-points {
	color: $color-white;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.event-content {
	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
	margin-bottom: 30px;
	width: 100%;
	word-wrap: break-word;
	color: $color-white;
}

.event-content-details {
	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
	margin-bottom: 30px;
	width: 100%;
	word-wrap: break-word;
}

.event-location {
	font-size: 16px;
	color: $color-black;
}

.event-card-btn-container-mobile {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	align-items: center;
	height: 15%;
}

.event-btn {
	height: 48px;
	border-radius: 8px;
	box-sizing: border-box;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 19px;
	width: 100%;
	margin-bottom: 16px;
	background: $card-bg;
}

.see-details-btn {
  background: #244354;
  border: 3px solid #00d9ef;
  color: #1be1f2;
  box-shadow: 0px 0px 10px 1px #00d9ef;
}

.add-to-calendar-btn {
	display: block;
	justify-content: center;
	width: 100%;
	margin-bottom: 16px;
	width: 164px;
	height: 47px;
}

.event-card-primary-btn-wrapper {
	display: flex;
	justify-content: center;
	margin-top: 24px;
}

.see-details-btn {
	background: #244354;
	border: 3px solid #00d9ef;
	color: #1be1f2;
	box-shadow: 0px 0px 10px 1px #00d9ef;
}

.add-to-cal-btn {
	background: $color-slalom-primary;
	border: 3px solid white;
	color: white;
	box-shadow: 0px 0px 10px 1px $color-slalom-primary;
}

.event-speaker-container {
	display: flex;
	justify-content: space-around;
	padding: 15px 0 30px;
	background: $transparent-light-purple;
	border: 1px solid $color-light-purple;
	border-radius: 20px;
}

.event-card-points-icon {
	width: 40px;
	height: 40px;
}

@media screen and (min-width: 1183px) {
	.event-card-container {
		width: 32%;
	}
}

@media screen and (max-width: 1182px) {
	.event-card-container {
		margin: 24px 16px 24px 0px;
	}
}

.learn-more-btn {
	display: block;
	justify-content: center;
	margin-bottom: 16px;
	width: 164px;
	height: 47px;
}