@import 'src/assets/sass/variables.scss';
@import 'src/assets/sass/mixins';

.spot-the-fake-container {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.spot-the-fake-subTitle {
    font-family: "Slalom Sans";
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    padding: 16px 0;
    z-index: 10;
    width: auto;

    @media screen and (max-width: 705px) {
        width: 232px;
    }
}

.spot-the-fake-image {
    width: 472px;
    height: 472px;
    z-index: 10;
    @media screen and (max-width: 705px) {
        width: 232px;
        height: 232px;
    }
}

.spot-the-fake-question {
    font-family: "Slalom Sans";
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 32px;
    margin-bottom: 16px;

    @media screen and (max-width: 705px) {
        width: 232px;
        margin-top: 24px;
        margin-bottom: 24px;
    }
}

.spot-the-fake-answer-choice {
    @include fontStandard();
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    padding: 19px 24px;
    background: $color-button-secondary;
    border: 1px solid $color-secondary-400;
    border-radius: 10px;
    max-width: 300px;
    flex-grow: 1;
    cursor: pointer;
    gap: 29px;

    @media screen and (max-width: 1440px) {
        flex-basis: 45%;
    }

    @media screen and (max-width: 705px) {
        max-width: 232px;
        flex-basis: 100%;
        max-height: 57px;
    }


    &:last-child {
        margin-right: 0;
    }

    &:hover {
        box-shadow: $box-shadow-green-glow;
        border-color: $color-secondary-300;

        .spot-the-fake-answer-choice-circle {
            box-shadow: 0px 1px 10px 0px $color-secondary-300;
            border-color: $color-secondary-300;
        }
    }

    &-circle {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border: 1px solid $color-secondary-400;
        height: 25px;
        width: 25px;
        min-width: 25px;
        border-radius: 50%;

        &__inner {
            width: 15px;
            height: 15px;
            background-color: transparent;
            border-radius: 50%;
        }
    }

    &-answer {
        flex-grow: 1;
        text-align: left;
        overflow-wrap: anywhere;
    }

    &-selected {
        font-weight: 400;
        border-width: 2px;
        background: linear-gradient(90deg,#0B0617 0%,#19553D 100%);



        .spot-the-fake-answer-choice-circle {
            background: none;
            border: 1px solid $color-secondary-300;

            &__inner {
                background-color: $color-secondary-300;
            }
        }
    }

    &-correct {
        background: $color-primary-500;
        border: 2px solid $color-secondary-300;
        cursor: initial;
        color: $color-white;
        font-weight: 400;

        &:hover {
            box-shadow: none;

            .spot-the-fake-answer-choice-circle {
                box-shadow: none;
                border-color: $color-secondary-300;
            }
        }

        .spot-the-fake-answer-choice-circle {
            border: 1px solid $color-secondary-300;

            &__inner {
                background-color: $color-secondary-300;

                &:hover {
                    box-shadow: none;
                }
            }
        }
    }

    &-wrong {
        background: $color-error-bg;
        border: 1px solid $color-input-error-border;
        cursor: initial;
        color: $color-white;
        font-weight: 400;

        &:hover {
            box-shadow: none;

            .spot-the-fake-answer-choice-circle {
                box-shadow: none;
                border-color: $color-input-error-bg;
            }
        }

        .spot-the-fake-answer-choice-circle {
            border: 1px solid $color-input-error-bg;

            &__inner {
                background-color: $color-input-error-bg;

                &:hover {
                    box-shadow: none;
                }
            }
        }

    }
}

.spot-the-fake-answers-container {
    display: flex;
    flex-direction: row;
    width: 628px;
    justify-content: space-between;
    z-index: 10;

    @media screen and (max-width: 705px) {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 130px;
            width: 232px;;
    }
}

.spot-the-fake-submission {
	&-container {
		padding: 0 16px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		justify-items: center;
        margin-top: 28px;
        z-index: 10;

		&.desktop {
			background: none;
			justify-content: flex-end;
		}

		&.tablet {
			background: none;
		}

		&.mobile {
			background: none;
		}
	}
}

.bg-cog {
	position: absolute;
    z-index: 1;
	
	&.bottom-left {
		bottom: 0;
		left: 0;
	}
	&.top-right {
		top: 0;
		right: 0 ;
	}
}

.spot-the-fake-message {
    font-family: "Slalom Sans";
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
}