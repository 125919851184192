@import 'src/assets/sass/variables.scss';
@import 'src/assets/sass/mixins';

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  transition: background 0.2s;
}

:root {
  --color-absent: #405069;
  --color-present: #e0c02b;
  --color-correct: #31ad23;
  --color-alert-success: #3fbf62;
  --color-alert-error: #ea4e2c;
  --color-toggle: #3fbf62;

  --color-background: #fff;
  --color-cell: #afadb0;
  --color-key: #e3e3e3;
  --color-key-text: #333;
  --color-key-secondary: #4f4f4f;
  --color-text-primary: #000;
  --color-text-secondary: #333;
  --color-icon: #333;

  [data-theme='dark'] {
    --color-background: #191919;
    --color-cell: #444444;
    --color-key: #4f4f4f;
    --color-key-text: #fff;
    --color-key-secondary: #fff;
    --color-text-primary: #fff;
    --color-text-secondary: #adadad;
    --color-icon: #fff;
  }

  [data-mode='high-contrast'] {
    --color-present: #06b6d4;
    --color-correct: #f97316;
  }
}


.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
