.grid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.row {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;
  margin: 0.25rem;
}

.jiggle {
  animation: jiggle;
  animation-duration: 0.3s;
}

@keyframes jiggle {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(-10px, 0);
  }
  50% {
    transform: translate(10px, 0);
  }
  75% {
    transform: translate(-10px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

@media screen and (max-width: 480px) {
  .grid {
    margin: 0;
  }
}
