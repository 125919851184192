.splash-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	font-family: SlalomSans;
	color: white;
}

.splashpage-background-image {
	z-index: -1;
	object-fit: cover;
	width: 100%;
	height: calc(100vh + 24px);
	position: fixed;
	top: -12px;
	left: 0;
}

