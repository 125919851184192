@import 'src/assets/sass/variables';
@import 'src/assets/sass/mixins';

.faq-tile-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 32px;
	justify-content: space-between;
	box-sizing: border-box;
	border-color: $color-black;
}

.faq-tile-title {
	text-transform: none;
	flex: 1;
	font-family: Slalom Sans;
	font-size: 20px;
	font-weight: 700;
	line-height: 22px;
	text-align: left;
	color: $color-secondary-300;
}

.faq-header > p {
	font-size: 18px;
}

.faq-tile-title-container {
	align-items: flex-start;
	display: flex;
	justify-content: space-between;
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 22px;
	border-radius: 16px;
	max-width: 1318px;
}

.faq-tile-body-container {
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 19px;
	margin-top: 32px;
	white-space: pre-wrap;
}

.faq-tile-arrow-icon {
	margin-left: 5px;
	display: true; //removing arrow for Hacktober 2023
}

// // This color is not specified in the Figma, yet appears on the FAQ page
// .faq-tile-container .app-card .app-card-content {
// 	background: #00010d;
// }

// There was a weird (barely visible) border that appeared behind the FAQ tiles
// This removes it. Might be breaking for other components.
.app-card {
	border: none;
}