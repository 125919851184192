.nav-logo-desktop {
	margin-left: 15px;
	align-self: center;
	z-index: 10;
}

.nav-logo-tablet,
.nav-logo-mobile {
	margin-left: 15px;
	margin-right: auto;
	align-self: center;
	z-index: 10;
}

.nav-logo > img {
	height: 50px;
}

.top-left {
  position: absolute;
  left: 24px;
  top: 24px;
  margin-left: 0;
}