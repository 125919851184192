@import 'src/assets/sass/variables.scss';
@import 'src/assets/sass/mixins';

.contact-card {
	.app-button-container {
		text-align: center;

		a {
			margin: 0 auto;
			min-width: 180px;
			width: 60%;
		}
	}
}

.contact-card-desktop {
	flex: 1;
	max-width: 100%;
}

.contact-card-tablet {
	min-height: 319px;
	flex-shrink: 0.2;

	.app-button-container {
		a {
			min-width: 200px;
			width: 80%;
		}
	}
}

.welcome-section-about-card {
	.app-card {
		min-height: auto;
		

		.app-card-content {
			padding: 32px;
			background: url(../../assets/images/hacktober-23-other-ways-card-background.svg) no-repeat;
			background-size: cover;
		}
	}
}

.bold {
	font-weight: bold;
}

.welcome-section-about-card.contact-section-container-tablet {
	.app-card {
		min-height: auto;
		width: 100%;
	}
}


.welcome-section-about-card.contact-section-container-mobile {
	.app-card {
		min-height: auto;
		width: 100%;
	}
}

.contact-section-container-desktop {
	display: flex;
	justify-content: space-between;
	align-items: stretch;
	gap: 20px;
	max-width: 100%;
    width: 100%;
    margin-bottom: 55px;
}

.contact-section-container-mobile {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 20px;
	width: 100%;
	overflow-x: auto;
	margin-top: 20px;
}

.contact-section-container-tablet {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 20px;
	width: 100%;
	overflow-x: auto;
	margin-top: 20px;
}
.code-hunt-bar {
	@include card();
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	width: 100%;
    height: 600px;
	padding: 24px;
	align-items: center;

}

.spot-the-fake-bar {
	@include card();
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	width: 100%;
	padding: 24px;
	align-items: center;
	position: relative;

}

.ai-image {
	object-fit: cover;
	max-width: 100%;
	width: auto;
	height: auto;
  }

.gallery-qr-bar {
	@include card();
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	width: 100%;
    height: 150px;
	padding: 24px;
	align-items: center;
}

.gallery-qr-row-desktop {
	display: flex;
	justify-content: center;
}

.gallery-qr-row-tablet,
.gallery-qr-row-mobile {
	flex-direction: row;
}

.gallery-qr-description-container {
	display: flex;
	flex-direction: column;
	font-weight: 300;
	font-size: 16px;
	line-height: 20px;
}

.gallery-qr-header {
	font-weight: 700;
	font-size: 16px;
	height: 20px;
	color: $color-white;
	margin-bottom: 32px;
}


.ar-description {
	margin-bottom: 32px;

	.app-card-content {
		@include card();
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 16px;

		p {
			flex-grow: 2;
			padding: 16px;

		}

		@media screen and (max-width: 1440px) {
			flex-direction: column;
		}

		@media screen and (max-width: 768px) {
			align-items: flex-start;
		}
	}
}

.ar-description-container {
	display: flex;
	align-items: center;
	border-radius: 16px;
	font-family: SlalomSans;
	color: $color-white;
	flex-direction: column;
	justify-content: space-between;
}

.ar-description-container.desktop {
	background: none;
	flex-direction: column;
}

.ar-description-container.tablet {
	background: none;
	flex-direction: column;
}

.ar-description-container.mobile {
	background: none;
	flex-direction: column;
}

.ar-description-container p {
	font-size: 16px;
	width: 1103px;
	height: 57px;
	font-weight: 400;
	padding: 16px;
	gap:16px;
}

.hacker-page-text-body-container {
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 20px;
	margin: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: auto;
	width: 100%;
}

.welcome-section {
	.about-page-text-body-container {
		height: auto;
		min-height: 160px;
	}
}

.spot-the-fake-description {
	margin-bottom: 32px;

	.app-card-content {
		@include card();
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 32px;

		.points-container {
			color: $color-secondary-300;
			display: flex;
			align-items: center;
			font-weight: bold;
			min-width: 222px;
			justify-content: normal;
			font-size: 24px;

			.points-icon {
				width: 32px;
				height: 32px;
				margin-right: 8px;
			}
		}

		p {
			flex-grow: 2;
		}

		@media screen and (max-width: 1182px) {
			flex-direction: column;
			p {
				padding-top: 16px;
			}
		}

		@media screen and (max-width: 768px) {
			align-items: flex-start;
		}
	}
}

.owasp-points {
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.owasp-points-desc {
	padding-right: 24px;
}

.challenge-points-left-align {
  white-space: nowrap;
  margin-right: 24px;
  font-size: 24px;
  font-weight: 700;
  color: $color-secondary-300;
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  > img {
    margin-right: 8px;
  }
}

.challenge-header-owasp {
	display: flex;
	flex-direction: row;
	
	@media  screen and (max-width: $breakpoint-md) {
		flex-direction: column;
		gap: 24px;
	}
}

.owasp-description {
	display: flex;
	flex-direction: column;
}

.juice-shop-button-container {
	display: flex;
	justify-content: center;
	padding-top: 36px;
	gap: 36px;

	@media  screen and (max-width: $breakpoint-md) {
		flex-direction: column;
		gap: 24px;
		align-items: center;
	}
}
