@import 'src/assets/sass/variables';
@import 'src/assets/sass/mixins';

// .events-tooltip {
// 	display: flex;
// 	justify-content: center;
// }

.events-container {
	max-width: 1320px;
	margin: auto;
	flex: none;
	order: 1;
	align-self: stretch;
	flex-grow: 0;

	.app-card-display {
		display: flex;
		flex-wrap: wrap;
		padding : 16px 32px  16px 32px;;

		.filter-row {
			display: flex;
			flex-basis: 100%;
		}

		.app-button-primary {
			width: 100%;
		}
	}
}

.filter-container {
	@include card();
	margin: 0px 0px 0px 0px;
	position: relative;
	z-index: 1;
}

.filter-container div {
	width: 100%;
}

.filter-container div>p {
	font-weight: 700;
	font-size: 14px;
	line-height: 21px;
	//margin-bottom: 1rem;
	letter-spacing: 0.02em;
	text-align: left;
	color: $color-grey-200;
	margin:0px;
}

.filter-label-tablet {
	display: none;
}

.filter-label-desktop {
	display: block;
}

.filter-block-desktop {
	margin-left: 1.5rem;
	margin-right: 1.5rem;
}

.filter-block-tablet,
.filter-block-mobile {
	margin-left: unset;
	margin-right: unset;
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.filter-container-desktop {
	display: flex;
	padding: 20px 0;
	margin-bottom: 28px;
}

.filter-container-mobile {
	// Prevents overflow of filter topics.
	// Longest category is "Customer Centricity"
	min-width: 332px;
}

.filter-row-desktop {
	display: flex;
	justify-content: flex-start;
	gap: 8px;
    padding: 16px 32px 16px 32px;
}

.filter-row-tablet,
.filter-row-mobile {
	flex-direction: row;
	padding: 16px 24px;
	gap: 24px;
	//margin-bottom: 28px;

	p {
		padding-top: 24px;
	}
}

.events-filter {
	margin-right: 0;
	position: relative;
	z-index: -2;

	&.desktop {
		margin-right: 20px;
	}
}

.dropdown-container {
	&.desktop {
		.app-button-container {
			margin-right: 20px;
		}
	}

	.app-button {
		height: 58px;
	}
}

.ui.dropdown:not(.button)>.default.text {
	font-family: 'Slalom Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: $color-white;
}

.ui.multiple.dropdown>.text {
	font-weight: 700;
	position: static;
	padding: 1rem;
	max-width: 100%;
	margin: unset;
	line-height: unset;
}

.ui.multiple.dropdown {
	box-shadow: unset !important;
}

.selected.item {
	font-weight: 400 !important;
	
}

.selected.item > .text:hover {
	font-weight: 700 !important;
	color: $color-cyan-light-1 !important;
}

.dropdown-label {
	font-size: 16px;
	font-weight: bold;
	margin-bottom: 15px;
}

//separate class for setting button label
.dropdown-btn-label{

}

.message-grid {
	display: inline-flex;
	flex-direction: row;
	align-items: stretch;
}

.message-grid-tablet {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: space-between;
}

.message-grid-mobile {
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	overflow-x: scroll;
	position: absolute;
}

.message-box {
	display: inline-block;
	flex: 100%;
	margin: 0px 32px 0px 0px;
	padding: 16px;
	border-radius: 20px;
	background: $color-white;
}

.message-box-last {
	margin-right: 0px;
}

.message-box-tablet {
	margin: 0px 0px 24px 0px;
}

.message-box-mobile {
	width: 350px;
	min-width: 300px;
	margin: 0px 16px 24px 0px;
	padding: 8px;
}

.modals.dimmer .ui.scrolling.modal .visible.transition {
	margin: 1rem auto;
	display: flex;
	justify-content: center;
}

.message-box-title {
	margin: 32px 32px 32px 32px;
	font-family: 'Slalom Sans';
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 29px;
	color: $color-black;
}

.message-box-title-mobile {
	font-size: 18px;
}

.message-box-content {
	margin: 16px 32px 32px 32px;
	font-family: 'Slalom Sans';
	font-style: normal;
	font-weight: 375;
	font-size: 16px;
	line-height: 20px;
	color: $color-chartreuse;
}

.event-card-grid {
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	overflow-x: auto;
	margin-bottom: 48px;
	position: relative;
	z-index: 0;
	scrollbar-color: $color-accent-1 transparent;

	@media screen and (max-width: 1183px) {
		.app-card.event-card-container {
			width: auto;
		}
	}
}

.claim-points-spacer {
	margin-bottom: 48px;
}

.event-about-container {
	display: flex;
	width: 100%;
}

@media screen and (min-width: 1183px) {
	.event-card-grid {
		flex-wrap: wrap;
		overflow: initial;
		margin-bottom: 48px;
	}
}

.temp-card-container {
	display: flex;
	max-width: 50%;
	margin: auto;
	margin-top: 30px;
	margin-bottom: -30px;
}

.claim-points-container {
	position: absolute;
	max-width: 95%;
	top: 75%;
	transform: translate(-50%, -50%);
	left: 50%;
}

.selected-event-option {
	border-bottom: 8px solid $color-secondary-300;
	//border-image: $color-teal-gradient-vertical 30;
	position: relative;
	//background: $color-teal-gradient-vertical;
	//-webkit-text-fill-color: transparent;
	background-clip: text;
	gap: 8px;
    padding: 24px;
	color: $color-secondary-300;

	&.mobile {
		padding: 0px;
	}
}

.selected-event-option:before {
	content: '';
	//background-image: url('../../assets/images/hacktober-23-events-selected-bg.png');
	//background-position: right;
	//background-repeat: no-repeat;
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: -1;
}

.mobile.selected-event-option:before {
	//background-image: url('../../assets/images/hacktober-23-events-selected-bg-mobile.png');
}

.unselected-event-option {
	border-bottom: 2px solid $color-grey-400;
	padding: 24px 24px 30px 24px; // extra pixels added to bottom padding to compensate border width difference between selected and unselected

	&.mobile {
		padding: 0px;
	}
}

.events-options {
	display: flex;
	cursor: pointer;
	width: 80%;
	align-items: center;
	//margin: 24px 24px 0;
	//border-bottom: 1px solid;
	//border-image: $color-teal-gradient-vertical 30;
	font-family: 'Slalom Sans';
	font-size: 24px;
	font-weight: 400;
	line-height: 28.8px;
	//letter-spacing: 0.3em;
	text-align: center;
	text-transform: uppercase;
	//background: $color-teal-gradient-vertical;
	//-webkit-text-fill-color: transparent;
	background-clip: text;
	padding: 16px 32px 16px 32px;

	&.desktop {
		margin-top: 0;
	}

	&.mobile {
		padding: 0px;
	}
}

.events-option {
	height: 54px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ui.selection.dropdown {
	border-radius: 10px;
}