@import 'src/assets/sass/variables.scss';
@import 'src/assets/sass/mixins';

.leaderboard-container {
	border-radius: 45px;
	display: flex;
	flex-direction: column;
	background-size: cover;
	background-repeat: no-repeat;
}

.Market-container,
.Office-container {
	border-radius: 16px;
	border: unset;

	.top-leader-stat-h2 {
		padding-bottom: 15px;
	}
}

.Local-container {
	border-radius: 16px;
}

.leaderboard-container-tablet,
.leaderboard-container-mobile {
	margin: 0;
}

.leaders {
	display: flex;
	justify-content: space-between;
	margin-bottom: 32px;

	&.leaders-small {
		margin-bottom: 0px;
	}
}

.leaders-mobile {
	position: relative;

	// Extend right side to match app-card-content length
	@include extend-overflow-right($length-app-card-content-padding);
	padding-right: 32px;
}

.leader-info {
	margin: 30px 0 0 30px;
}

.leader-place {
	background-size: cover;
	background-repeat: no-repeat;
	border: 3px solid #008bff;
	border-radius: 16px;
	background-color: rgba(9, 9, 28, 0.5);
	margin-right: 15px;
}

.leader-place-large {
	height: 257px;
	width: 387px;
}

.leader-place-tablet {
	height: 300px;
	min-width: 182px;
}

.leader-place-mobile {
	height: 230px;
	min-width: 152px;
}

.leader-avatar {
	border-radius: 50%;
	height: 60px;
	width: 60px;
	border: 3px solid #008bff;
}

.leader-stats {
	font-family: Slalom Sans;
	font-weight: 700;
	font-size: 24px;
	line-height: 28px;
}

.leader-market {
	font-family: Slalom Sans;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
}

.leader-points {
	font-family: Slalom Sans;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
}

.leader-ranking {
	font-family: Slalom Sans;
	font-weight: 700;
	font-size: 24px;
	line-height: 28px;
}

.listing-participant {
	width: 100%;
	display: flex;
	align-items: center;
}

.listing-participant>img {
	margin-right: 10px;
}

.leader-listing-container {
	display: grid;
}

.leader-listing-container-mobile {
	display: grid;
	grid-template-columns: 10% 5% 45% 20% 20%;
}

.leader-listing-headers {
	display: grid;
	grid-template-columns: 10% 5% 45% 20% 20%;
	grid-column-start: 1;
	grid-column-end: -1;
	justify-items: center;
	align-items: center;
	margin-bottom: 25px;
	margin-top: 15px;
	font-size: 25px;
	font-weight: bold;
	line-height: normal;
}

#name-header {
	grid-column-start: 3;
	grid-column-end: 4;
}

#market-header {
	grid-column-start: 4;
	grid-column-end: 5;
}

#points-header {
	grid-column-start: 5;
	grid-column-end: 6;
}

.leader-listing {
	display: grid;
	grid-template-columns: 5% 25% 40% 30%;
	height: 72px;
	width: 100%;
	grid-column-start: 1;
	grid-column-end: -1;
	justify-items: center;
	align-items: center;
	margin-bottom: 16px;
	background: $color-green-gradient-dark-vertical2;
	color: $color-white;
	padding-left: 25px;
	padding-right: 25px;
	border: 2px solid $color-secondary-400;
	border-radius: 4px;

	&:last-child {
		margin-bottom: 0;
	}
}

// First column of every row in the leader listing
.leader-listing>*:first-child {
	justify-self: start;
}

// Last column of every row in the leader listing
.leader-listing>*:last-child {
	justify-self: end;
}

.leader-listing-market {
	grid-template-columns: 10% 50% 20% 20%;
}

.listing-text-style {
	font-family: Slalom Sans;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
}

.listing-text-style-rank {
	font-family: Slalom Sans;
	font-weight: 700;
	font-size: 14px;
	line-height: 21px;
}

.listing-avatar {
	width: 35px;
	height: 35px;
	border-radius: 50%;
	grid-column-start: 2;
	grid-column-end: 3;
}

.listing-name {
	grid-column-start: 3;
	grid-column-end: 4;
	grid-row-start: 1;
}

.listing-market {
	grid-column-start: 4;
	grid-column-end: 5;
}

.listing-points {
	grid-column-start: 5;
	grid-column-end: 6;
}

.listing-rank {
	font-weight: 900;
	grid-column-start: 1;
	grid-column-end: 2;
}

.leaderboard-default-msg {
	font-family: Slalom Sans;
	font-weight: 700;
	font-size: 24px;
	line-height: 28px;
	text-align: center;
	color: $color-white;
	padding: 32px;
}

.leaderboard-options {
	cursor: pointer;
	display: flex;
	justify-content: space-around;
	align-items: center;
	margin-bottom: 32px;
}

.leaderboard-option {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	font-family: Slalom Sans;
	font-weight: 400;
	font-size: 24px;
	line-height: 28px;
	text-transform: uppercase;
	border-bottom: 2px solid $color-grey-400;
	padding-bottom: 12px;

	@media screen and (max-width: 1182px) {
		font-size: 18px;
		line-height: 23px;
	}
}

.leaderboard-option-icon {
	height: 42px;
	width: 42px;
	margin-right: 20px;
}

.selected-leaderboard-option {
	color: $color-secondary-300;
	border-bottom: 8px solid $color-secondary-300;
	padding-bottom: 6px;
}

.leaderboard-option.unselected-leaderboard-option {
	color: $color-white;
}

.leaderboard-options-mobile {
	font-family: Slalom Sans;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	display: flex;
	flex-direction: column;
	margin-bottom: 20px;
}

.leaderboard-option:hover {
	color: $color-secondary-300;
}

.leaderboard-tooltip a {
	color: $color-secondary-400;
	text-decoration: underline;
}

@media screen and (max-width: 705px) {
	.leaders {
		overflow-x: scroll;
		-webkit-overflow-scrolling: touch;
		margin-bottom: 0;
		overflow-y: hidden;
		scrollbar-color: $color-accent-1 transparent;
	}
}

.leader-listing .points-card {
	font-size: 20px;
	font-weight: 700;
}