@import 'src/assets/sass/variables.scss';

.wrap-page-bg {
	min-height: 100vh;

	background: $color-gray-gradient-background, url('../../assets/images/Background.jpg');
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	background-attachment: scroll;
}

.wrap-page {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	height: 100vh;

	.footer-container {
		width: 100%;
	  }
}

.wrap-page-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex-grow: 1;
}

// Override styling for Card component specific to the Wrap Page design
.wrap-card {
	.app-card-content {
		padding: 32px 60px;
	}

	// Expand the app button container
	.app-button-generic-container {
		min-width: 270px;

		.app-button {
			width: 100%;
		}
	}

	// Keep symbols from bumping into sides of visual card container
	.section-header-icon.mobile {
		margin: 0px;
	}

	// Show symbols on right side of section header for Hacktober 2023 mobile
	.section-header-icon.mobile.flip {
		display: block;
	}

	// Set the size of the app card
	&-desktop {
		width: 986px;

		// Reduce the font from 40px to 32px due to length
		// THANK YOU FOR PARTICIPATING
		.section-title.desktop {
			font-size: 32px;
		}
	}

	&-tablet {
		width: 580px;
		// Allow section title on tablet to wrap
		// This is already the default behavior for mobile
		.section-title.tablet {
			flex-shrink: 1;
			white-space: normal;
			}
	}

	&-mobile {
		width: 320px;
		// Revert padding to 32px all around on mobile 
		.app-card-content {
			padding: 32px;
		}
	}
}

.top-buttons {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 20px;
	margin: 36px auto;

	// Display the buttons in a column for tablet and mobile
	&-tablet, &-mobile {
		flex-direction: column;
	}
}
