@import 'src/assets/sass/variables';
@import 'src/assets/sass/mixins';

.prelaunch-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	font-family: SlalomSans;
	color: white;
}

.prelaunch-container-desktop > div {
	text-align: center;
}

.prelaunch-announcement-card-desktop {
	padding: 16px 32px 16px 32px;
	background-color: rgba(11, 6, 23, 1);
	border: 3px solid rgba(27, 180, 23, 1);
	border-radius: 20px;
	display: flex;
}

.prelaunch-announcement-card-tablet {
	padding: 32px 32px 16px 32px;
	background-color: rgba(11, 6, 23, 1);
	border: 3px solid rgba(27, 180, 23, 1);
	border-radius: 20px;
	width: 560px;
	display: flex;
	flex-direction: column;
}

.prelaunch-announcement-card-mobile {
	padding: 16px 32px 16px 32px;
	margin: 50px 24px 0px 24px;
	background-color: rgba(11, 6, 23, 1);
	border: 3px solid rgba(27, 180, 23, 1);
	border-radius: 20px;
	display: flex;
	flex-direction: column;
}

.prelaunch-announcement-text {
	font-family: Slalom Sans;
	font-weight: 700;
	font-size: 18px;
	max-width: 436px;
	padding: 24px 0px 24px 0px;
	margin: auto 16px auto 16px;
	text-align: center;
}

.prelaunch-announcement-img {
	height: 84px;
	margin: auto;
}

.prelaunch-background-image {
	z-index: -1;
	object-fit: cover;
	width: 100vw;
	height: calc(100vh + 24px);
	position: fixed;
	top: -12px;
	left: 0;
}

.prelaunch-topic-container-desktop {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	margin: 0 auto;
	font-family: SlalomSansBold;
	text-align: center;
	height: 60vh;
}

.prelaunch-topic-container-tablet {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	margin: 0 auto;
	font-family: SlalomSansBold;
	text-align: center;
	height: 60vh;
}

.prelaunch-content-desktop {
	width: 1080px;
	
	
}

.prelaunch-content-tablet {
	width: 700px;
}

.prelaunch-content-mobile {
	width: 320px;
}

.prelaunch-content-desktop h2 {
	font-size: 18px;
	line-height: 22px;
	margin-bottom: 16px;
}

.prelaunch-content-tablet h2 {
	font-size: 16px;
	line-height: 22px;
	margin-bottom: 16px;
}

.prelaunch-content-mobile h2 {
	font-size: 12px;
	line-height: 18px;
	margin: 8px 0;
}

.prelaunch-content-desktop p,
.prelaunch-content-tablet p,
.prelaunch-content-mobile p {
	display: inline-block;
	margin: 0;
}

.prelaunch-topic-name-desktop {
	font-size: 32px;
	line-height: 38px;
}

.prelaunch-topic-name-tablet {
	font-size: 18px;
	line-height: 22px;
}

.prelaunch-topic-name-mobile {
	font-size: 12px;
	line-height: 14px;
}

.prelaunch-topics {
	line-height: 12px;
}

.prelaunch-festival-title-desktop {
	width: 650px;
	margin: 0 auto;
	padding: 64px 0 24px 0;
}

.prelaunch-festival-title-tablet {
	width: 500px;
	margin: 0 auto;
	padding: 64px 0 24px 0;
}

.prelaunch-festival-title-mobile {
	width: 260px;
	margin: 0 auto;
	padding: 48px 0 16px 0;
}

.app-button-generic-container {
	margin: 0;
}

@media screen and (max-width: 705px) {
	.app-button-generic-container {
		margin: 0;
	}
}

.button-container-desktop {
	display: flex;
	justify-content: center;
	gap: 20px;
	margin-top: 36px;
	
}

.button-container-tablet {
	display: flex;
	justify-content: center;
	margin-top: 36px;
	gap: 20px;
}

.button-container-mobile {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 36px;
	gap: 20px;
}

.pride-description {
	width: 580px;
	font-size: 22px;
	font-family: 'Slalom Sans';
	line-height: 26px;
	text-align: center;
	font-weight: 400;
	margin: 0 auto 48px auto;
}

.pride-description.mobile {
	width: 250px;
	margin: 0 0 24px 0;
	text-align: left;
}

.prelaunch-card-container-desktop {
	width: 1320px;
	margin-top: 64px;
	text-align: center;
	align-self: center;
}

.prelaunch-card-container-tablet {
	display: flex;
	margin-left: 20px;
	margin-right: 20px;
}

.prelaunch-card-title {
	font-weight: 700;
	margin-bottom: 32px;
	font-style: normal;
	font-size: 24px;
}

.prelaunch-card-body {
	margin-bottom: 32px;
}

.prelaunch-card-button-container-desktop {
	margin: auto;
	
}

.prelaunch-card-button-container-mobile {
	margin: auto;
}

.bold {
	font-weight: 700;
}

.lineup-entry-desktop,
.lineup-entry-tablet {
	font-weight: 700;
	font-size: 32px;
	line-height: 150%;
	// display: inline-flex;

	&__pink {
		color: #ff8293;
	}
}

.lineup-entry-mobile {
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;

	&__pink {
		color: #ff8293;
	}
}

.play-pause {
	width: 40px;
	height: 40px;
	position: absolute;
	top: 20px;
	right: 20px;
}

.play-pause-xl {
	width: 40px;
	height: 40px;
	position: absolute;
	top: 40px;
	left: 85%;
}

.lineup-container-desktop .lineup-entry-desktop:not(:first-child):before,
.lineup-container-tablet .lineup-entry-tablet:not(:first-child):before {
	content: '•';
	margin-right: 4px;
	margin-left: 4px;
	color: #ff8293;
}

.lineup-container-desktop.lineup-entry-desktop:nth-child(n + 15):before,
.lineup-container-tablet .lineup-entry-tablet:nth-child(n + 15):before {
	content: '\A';
	white-space: pre;
}

.lineup-container-desktop .test:before,
.lineup-container-tablet .test:before {
	content: '•';
	margin-right: 4px;
	margin-left: 4px;
	color: #ff8293;
}

.lineup-container-mobile .lineup-entry-mobile:before {
	content: '\A';
	white-space: pre;
}

.lineup-container-desktop {
	font-weight: 700;
	font-size: 32px;
	line-height: 150%;
	width: 1160px;
	margin: auto;
}

.lineup-container-tablet {
	font-weight: 700;
	font-size: 32px;
	line-height: 150%;
	margin-left: 0;
	padding-left: 0;
	justify-content: center;
	text-align: center;
}

.lineup-container-mobile {
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	margin-left: 0;
	padding-left: 0;
	justify-content: center;
	text-align: center;
}

.divider-desktop {
	width: 1160px;
	height: 0px;
	margin-top: 32px;
	margin-bottom: 32px;
	border: 2px solid #000000;
}

.divider-tablet {
	width: 590px;
	height: 0px;
	margin-top: 32px;
	margin-bottom: 32px;
	border: 2px solid #000000;
}

.divider-mobile {
	width: 250px;
	height: 0px;
	margin-top: 32px;
	margin-bottom: 32px;
	border: 2px solid #000000;
}

.app-button-secondary {
    @include longButtonTexts;
  }

// .peace-sign-desktop {
// 	position: absolute;
// 	left: 451.23px;
// 	top: 1294.63px;
// 	z-index: 1;
// }

// @media (min-width: 576px) {
// 	.button-mt {
// 		margin-bottom: calc(100vh - 50em);
// 	}
// }
