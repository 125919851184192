@import "src/assets/sass/mixins";

.announcement-container-desktop {
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 10px;
  z-index: 1000;
  width: 1320px;
  height: auto;
  overflow-y: auto;
}

.announcement-container-tablet {
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1000;
  width: 95%;
  height: auto;
  overflow-y: auto;
}

.announcement-container-mobile {
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1000;
  width: 95%;
  height: auto;
  overflow-y: auto;
}

.announcement-desktop,
.announcement-tablet,
.announcement-mobile {
  background-color: $color-border-primary;
	padding: 10px;
	color: $color-black;
	line-height: 19px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  font-family: Slalom Sans;
  letter-spacing: 0.25px;
  font-size: 14px;
  font-weight: bold;
}

.announcement-close {
  height: 14px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 16px;
  cursor: pointer;
}
