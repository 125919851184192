@import 'src/assets/sass/variables';
@import 'src/assets/sass/mixins';

.rankings-container {
	display: flex;
	gap: 16px;
	color: $color-white;
	font-weight: 700;
	z-index: 10;
	font-size: 16px;
	align-items: center;

	margin-left: 0.7rem;

	.local-ranking,
	.global-ranking {
		font-size: 16px;
		align-self: center;
		cursor: default;
		font-weight: 700;
		display: block;
	}
}