.section-header {
	display: flex;
	justify-content: center;
	width: 100%;

	.section-title {
		align-items: center;
		text-align: center;
		text-transform: capitalize;

		&.mobile {
			font-size: 34px;
		}

		&.tablet {
			font-size: 34px;
		}

		&.desktop {
			font-size: 48px;
		}
	}

	.section-header-icon {
		min-width: 21px;
		background-repeat: no-repeat;
		background-position: center;
		background-image: url("../../assets/images/Graphic_Left.svg");
		background-size: contain;
		margin: 0 16px;
				
		&.flip {
			transform: scaleX(-1);
		}

		&.mobile {
			width: 48px;
			height: 8px;
		}

		&.tablet {
			width: 72px;
			height: 12px;
		}

		&.desktop {
			width: 96px;
			height: 16px;
		}
	}
}
