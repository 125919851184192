@import 'src/assets/sass/variables.scss';
@import 'src/assets/sass/mixins';

.prizes-list-container .flex-container>.app-card {
	margin-right: 32px;
	flex: 1;
}

.prizes-list-container .flex-container>.app-card:last-child {
	margin-right: 0;
}

.prizes-list {
	justify-content: space-between;
}

.prizes-list-container {
	margin: 32px 0;
}

@media screen and (max-width: 1182px) {
	.prizes-list {
		max-width: inherit;
		overflow-x: auto;
		width: 100%;
	}

	.prizes-list-container .flex-container>.app-card {
		min-width: 390px;
	}
}