.mini-game-container {
  width: 700px;
  height: 300px;
  border: 1px solid black;
  margin: auto;
  background-color: lightblue;
  overflow: hidden;

  // Commenting out positioning so minigame can follow pagewrapper positioning for now
  // position: absolute;
  // // top: -30vh;
  // left: 50%;
  // transform: translate(-50%, -50%);
  .mini-game-score {
    position: relative;
    top: -89%;
    margin-left: 10px;
  }

  .start-game-message {
    position: absolute;
    top: 59%;
    left: 50%;
    transform: translate(-50%, -50%);

    .main-container-large & {
      top: 61% 
    } 

    .main-container-tablet & {
      top: 59% 
    } 
  }

  .yeti {
    width: 60px;
    height: 50px;
    background-image: url(../../assets/images/chat-avatar-no-help.png);
    background-position: center;
    position: relative;
    top: 250px;
    left: 50px;
    z-index: 20;
  }
  .snowball {
    width: 40px;
    height: 40px;
    position: relative;
    top: 210px;
    left: 560px;
    background-image: url(../../assets/images/miniGameImages/mini-game-snowball-1.png);
    background-size: 40px 40px;
    z-index: 20;
  }
  .cloud {
    width: 120px;
    height: 60px;
    position: relative;
    top: -40px;
    left: 600px;
    background-image: url(../../assets/images/miniGameImages/cloud1.png);
  }
  .cloud2 {
    width: 150px;
    height: 91px;
    position: relative;
    top: -150px;
    left: 500px;
    background-image: url(../../assets/images/miniGameImages/cloud2.png);
  }
  .cloud3 {
    width: 80px;
    height: 24px;
    position: relative;
    top: -150px;
    left: 400px;
    background-image: url(../../assets/images/miniGameImages/cloud3.png);
  }
  .ground {
    height: 30px;
    width: 1000px;
    position: relative;
    top: 10px;
    background-image: url(../../assets/images/miniGameImages/ground_pattern.png);
  }

  .ground2 {
    height: 30px;
    width: 1000px;
    position: relative;
    top: 10px;
    background-image: url(../../assets/images/miniGameImages/ground_pattern.png);
  }

  .snowball-animation {
    animation: block linear;
  }

  .cloud-animation {
    animation: cloud 30s infinite linear;
  }

  .cloud2-animation {
    animation: cloud 40s infinite linear;
  }

  .cloud3-animation {
    animation: cloud 20s infinite linear;
  }

  .ground-animation {
    animation: ground 20s infinite linear;
  }

  .ground2-animation {
    animation: ground2 20s infinite linear;
  }

  .jump {
    animation: jump 0.5s ease-in-out;
  }
}

@keyframes jump {
  0% {
    top: 250px;
  }
  50% {
    top: 150px;
  }
  100% {
    top: 250px;
  }
}

@keyframes block {
  0% {
    left: 100rem;
  }

  100% {
    left: -20px;
  }
}

@keyframes cloud {
  0% {
    left: 100%;
  }
  100% {
    left: -30%;
  }
}

@keyframes ground {
  0% {
    left: 100%;
  }
  100% {
    left: 0%;
  }
}

@keyframes ground2 {
  0% {
    left: 0;
  }

  100% {
    left: -1000px;
  }
}
