@import 'src/assets/sass/variables.scss';
@import 'src/assets/sass/mixins';

.cell {
  width: 64px;
  height: 64px;
  background-color: transparent;
  font-size: 2.6rem;
  font-weight: 700;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
  padding: var(--Spacing1andhalf);
  gap: var(--Spacing1);
  border: 1px solid var(--Button-Secondary-default-label, #1FF808)
  
}

.fill {
  animation: typeIn;
  animation-duration: 0.2s;
}

.reveal {
  animation-duration: 0.3s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;

  &.absent {
    animation-name: revealAbsentCell;
  }

  &.correct {
    animation-name: revealCorrectCell;
  }

  &.present {
    animation-name: revealPresentCell;
  }

  .letter {
    animation-duration: 0.3s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    animation-name: rotateLetter;
  }
}

@keyframes typeIn {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.15);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes revealAbsentCell {
  0% {
    transform: rotateX(0deg);
    background-color: var(--color-cell);
  }
  50% {
    background-color: var(--color-cell);
  }
  50.5% {
    background-color: var(--color-absent);
  }
  100% {
    transform: rotateX(180deg);
    background-color: var(--color-absent);
  }
}

@keyframes revealPresentCell {
  0% {
    transform: rotateX(0deg);
    background-color: var(--color-cell);
  }
  50% {
    background-color: var(--color-cell);
  }
  50.5% {
    background-color: var(--color-present);
  }
  100% {
    transform: rotateX(180deg);
    background-color: var(--color-present);
  }
}

@keyframes revealCorrectCell {
  0% {
    transform: rotateX(0deg);
    background-color: var(--color-cell);
  }
  50% {
    background-color: var(--color-cell);
  }
  50.5% {
    background-color: var(--color-correct);
  }
  100% {
    transform: rotateX(180deg);
    background-color: var(--color-correct);
  }
}

@keyframes rotateLetter {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(180deg);
  }
}

@media screen and (max-width: 480px) {
  .cell {
    width: 55px;
    height: 55px;
    font-size: 2.4rem;
  }
}
