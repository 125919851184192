@import 'src/assets/sass/variables.scss';
@import 'src/assets/sass/mixins';

.donate-section-container {
    background: none;
    flex-direction: row;
    max-width: 1320px;
    margin: 0 auto;
}

.donate-description {
    margin-bottom: 32px;
    .app-card-content {
        @include card();
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        p {
            flex-grow: 2;

            @media screen and (max-width: ($breakpoint-md)) {
                padding: 0px 16px;
                justify-content: left;
            }
        }

        .donate-container {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 222px;
        
            p {
                font-size: 24px;
                font-weight: 700;
                padding-right: 16px;
            }
        
            @media screen and (max-width: ($breakpoint-md)) {
                padding-bottom: 16px;
                justify-content: flex-start;
            }
        }

        .app-button-container {
            display: flex;
            min-width: 190px;
            align-items: center;
            justify-content: flex-end;
            margin-left: auto;

            @media screen and (max-width: ($breakpoint-md)) {
                justify-content: flex-start;
                padding-left: 8px;
                padding-top: 8px;
                margin-left: 0;
            }
        }

        @media screen and (max-width: ($breakpoint-md)) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
        }
    }
}