.app-video {
	position: fixed;
	z-index: -1;
	width: 100vw;
	height: 100vh;

	&__play-pause-icon {
		cursor: pointer;
		position: fixed;
		right: 40px;
		top: 40px;
		width: 40px;
		height: 40px;
	}

	video {
		z-index: -1;
		object-fit: cover;
		width: 100vw;
		height: 100vh;
		position: fixed;
		top: 0;
		left: 0;
	}
}
