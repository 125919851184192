@import 'src/assets/sass/variables.scss';
@import 'src/assets/sass/mixins';

.quiz-description {
	margin-bottom: 16px;

	.app-card-content {
		@include card();
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 32px;
		gap:0.8px;


		p {
			flex-grow: 2;
			//padding: 16px;
		}

		@media screen and (max-width: 1440px) {
			flex-direction: row;
		}

		@media screen and (max-width: 768px) {
			align-items: flex-start;
			flex-direction: column;
		}
	}
}

.quiz-description-container {
	display: flex;
	align-items: center;
	border-radius: 16px;
	font-family: SlalomSans;
	color: $color-white;
}

.quiz-description-container.desktop {
	background: none;
	flex-direction: row;
}

.quiz-description-container.tablet {
	background: none;
	flex-direction: column;
}

.quiz-description-container.mobile {
	background: none;
	flex-direction: column;
}

.quiz-description-container p {
	font-size: 16px;
	padding-right: 32px;
}

.points-container {
	color: $color-white;
	display: flex;
	padding: 0px;
	align-items: center;
	font-weight: bold;
	min-width: 222px;
	justify-content: normal;
	gap: 8px;

	&__title {
		@include cardTitle();
		font-size: 24px;
		//margin-bottom: 16px;
		line-height: 28.8px;

		@media screen and (max-width: 1440px) {
			text-align: start;
			//margin-bottom: 48px;
		}

		@media screen and (max-width: 768px) {
			text-align: start;
			//margin-bottom: 32px;
		}
	}

}

.database-icon {
	//padding-right: 16px;
}

.quiz-content {
	.app-card-content {
		padding: 32px;
		gap: 32px;
		//background-image: url("../../assets/images/QOTD-background.png");
		background-size: cover;
		background-color: $color-primary-700;
	}

	&__title {
		@include cardTitle();
		font-size: 24px;
		//margin-bottom: 16px;
		line-height: 28.8px;
		padding-bottom: 32px;

		@media screen and (max-width: 1440px) {
			text-align: start;
		}

		@media screen and (max-width: 768px) {
			text-align: start;
		}
	}

	&__section {
		//padding: 32px 16px 16px;
	}
}

.quiz-content-container {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	border-radius: 16px;
	font-family: SlalomSans;
	color: $color-white;
}

.quiz-question {
	font-size: 16px;
	line-height: 24px;
}

.quiz-icon-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.quiz-answers-container {
	//padding: 0px 16px 16px;
	display: flex;
	flex-direction: column;
	align-items: stretch;
}

.quiz-answers-row {
	display: flex;
	flex-direction: row;
	flex-flow: row wrap;
	//justify-content: center;
	gap: 16px;

	@media screen and (max-width: 1440px) {
		flex-wrap: wrap;
	}
}

.quiz-button {
	@include fontStandard();
	line-height: 19.2px; //Override for submit button Hacktober 2024
	font-weight: 700; //Override for submit button Hacktober 2024

	display: flex;
	flex-direction: row;
	align-items: center;
	position: relative;
	padding: 19px 24px;
	background: $color-black;
	border-image-slice: 1;
	//border-color: $color-secondary-400;
	border: 1px solid $color-secondary-400;
	border-radius: 10px;
	//max-width: 600px;
	flex-grow: 1;
	cursor: pointer;
	gap: 29px;
	flex-basis: 45%;

	@media screen and (max-width: 1440px) {
		max-width: none;
		flex-basis: 45%;
	}

	@media screen and (max-width: 768px) {
		max-width: none;
		flex-basis: 100%;
	}


	&:last-child {
		margin-right: 0;
	}

	/* Hacktober 2024 - disable hover styling
	&:hover {
		box-shadow: $box-shadow-teal-glow;
		border-color: $color-cyan-light-1;

		.quiz-button-circle {
			box-shadow: 0px 1px 10px 0px $color-cyan-light-1;
			border-color: $color-cyan-light-1;
		}
	}
	*/

	&-circle {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: transparent;
		border: 1px solid $color-secondary-400; //Hacktober 2024
		height: 24px;
		width: 24px;
		min-width: 24px;
		border-radius: 50%;

		&__inner {
			width: 14.4px;
			height: 14.4px;
			background-color: transparent;
			border-radius: 50%;
		}
	}

	&-answer {
		flex-grow: 1;
		text-align: left;
		overflow-wrap: anywhere;
		font-weight: 400;
	}

	&-selected {
		font-weight: 400;
		border-color: $color-secondary-400;
		//background-image: url('../../assets/images/hacktober-23-single-select-background.svg');
		background-image: $color-green-gradient-dark-horizontal1;
		background-color: $color-primary-700;
		background-position: right;
		background-size: cover;



		.quiz-button-circle {
			background: none;
			border: 2px solid $color-secondary-300;

			&__inner {
				background-color: $color-secondary-300;
			}
		}
	}

	&-correct {
		box-shadow: none;
		background-color : $color-primary-500;
		cursor: initial;
		//color: $color-dark-blue;
		font-weight: 400;

		/* Hacktober 2024 - disable hover styling
		&:hover {
			box-shadow: none;

			.quiz-button-circle {
				box-shadow: none;
				border-color: $color-green;
			}
		}
		*/

		.quiz-button-circle {
			background: none;
			border: 2px solid $color-secondary-300;

			&__inner {
				background-color: $color-secondary-300;
			}
		}
	}

	&-wrong {
		box-shadow: none;
		background-color : $color-error;
		border: 1px solid $color-input-error-border;
		cursor: initial;
		//color: $color-dark-blue;
		font-weight: 400;

		/*
		&:hover {
			box-shadow: none;

			.quiz-button-circle {
				box-shadow: none;
				border-color: $color-red;
			}
		}
		*/

		.quiz-button-circle {
			background: none;
			border: 1px solid $color-input-error-bg;

			&__inner {
				background-color: $color-input-error-bg;

				/*
				&:hover {
					box-shadow: none;
				}
					*/
			}
		}

	}
}

.quiz-button.desktop {
	background: none;
	width: 23%;
}

.quiz-button.tablet {
	background: none;
	width: 48%;
}

.quiz-button.mobile {
	background: none;
	width: 98%;
}

.quiz-button-description {
	display: inline-block;
	padding: 10px 75px 10px 25px;
}

.quiz-submission {
	&-container {
		padding: 0 16px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		justify-items: center;

		&.desktop {
			background: none;
			justify-content: flex-end;
		}

		&.tablet {
			background: none;
		}

		&.mobile {
			background: none;
		}
	}

	&-content {
		color: $color-gray;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 8px;
		width: 100%;

		.app-button-generic-container {
			margin-bottom: 8px;
			width: 100%;
			display: flex;
			justify-content: center;
		}

		p {
			font-size: 14px !important;
		}

		@media screen and (max-width: 320px) {
			max-width: 130px;

			p {
				text-align: center;
			}
		}
	}
}

.correct-explanation {
	font-size: bold;
	color: white;
}

.wrong-explanation {
	font-size: bold;
	color: white;
	margin-bottom: 16px;
}

.section-qotd {
	display: flex;
	flex-direction: column;
	position: relative;
	justify-content: flex-start;
	flex-grow: 1;
	width: 100%;
}

.quiz-of-the-day {
	background: none;
}

.overlay {
	position: absolute;
	z-index: 2;
}