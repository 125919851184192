@import './variables';
@mixin title-xl {
	font-family: Slalom Sans;
	font-size: 24px;
	font-weight: 700;
	line-height: 29px;
	letter-spacing: 0em;
	text-align: left;
	text-shadow: 0px 0px 10px #C094BC;
	flex: none;
	order: 0;
	flex-grow: 1;
}
@mixin title-sm {
	font-family: Slalom Sans;
	font-size: 18px;
	font-weight: 700;
	line-height: 22px;
	letter-spacing: 0em;
	text-align: left;
	text-shadow: 0px 0px 10px #C094BC;
	flex: none;
	order: 0;
	flex-grow: 1;
}
@mixin body {
	font-family: Slalom Sans;
	font-size: 16px;
	font-weight: 400;
	line-height: 19px;
	letter-spacing: 0em;
	text-align: left;
}
@mixin cardSecondary {
	@include card();
	border: 2px solid $color-black;
	box-shadow: $card-shadow;
}
@mixin fontBase {
	color: $color-white;
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	line-height: 24px;
}
@mixin fontStandard {
	@include fontBase();
	font-family: 'Slalom Sans';
}
@mixin fontSecondary {
	@include fontBase();
	font-family: 'Slalom Sans';
}
@mixin card {
	color: $color-white;
	font-family: 'Slalom Sans';
	background: $color-primary-700;
	border: 3px solid $color-secondary-400;
	border-radius: 10px;
	p {
		@include fontStandard();
	}
}
@mixin cardTitle {
	@include fontSecondary();
	font-weight: 700;
	font-size: 24px;
	line-height: 31px;
	@media screen and (max-width: 1182px) {
		font-size: 20px;
		line-height: 24px;
	}
}
@mixin leaderboardCard {
	color: $color-black;
	box-shadow: $box-shadow;
	overflow: hidden;
	// Background properties
	background: url('../../assets/images/leaderboard-bg.svg'), #FFFFFF;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	background-clip: content-box;
	mix-blend-mode: normal;
	opacity: 0.95;
}
@mixin fontHeader {
	@include fontStandard();
	font-family: 'SpectralSC';
	font-size: 48px;
	font-weight: 400;
	letter-spacing: 0.05em;
	line-height: 60px;
	padding:4px;
	gap:4px;
}
@mixin headerFooter {
	background: rgba(238, 233, 255, 1);
	backdrop-filter: blur(20px);
}

@mixin sectionHeader {
	@include fontHeader();
	margin: 16px 0px;
	display: flex;
	color: $color-off-white;
	// This creates a border around the text of 1px and has a drop shadow
	text-shadow: $text-shadow;
	text-transform: uppercase;
}
@mixin labelHighlighted {
	background-color: $color-cyan-light-1;
	padding: 10px;
	color: $color-black;
	font-weight: 700;
	line-height: 19px;
}
@mixin button {
	@include fontStandard();
	padding: 1rem;
	text-align: center;
	border: none;
	width: 100%;
	text-transform: uppercase;
	&:hover {
		cursor: pointer;
	}
	&:disabled {
		background-image: none;
		background-color: $color-dark-gray;
		border: none;
		color: $color-grey-400;
		cursor: not-allowed;
		&:hover {
			cursor: not-allowed;
		}
	}
}
@mixin buttonPrimary {
	@include button();
	background: $color-button-gradient;
	color: $color-button-primary-default-label;
	font-weight: bold;
	padding: 24px 36px;
	border-radius: 4px;
	border: 1px solid;
	border-color: $color-border-primary;
	//box-shadow: $button-shadow;

	&:hover {
		background: $color-button-primary-hover;
		color: $color-button-primary-default-label;
	}
	
	&:active {
		background: $color-button-primary-pressed;
		color: $color-button-primary-default-label;
		box-shadow: $box-shadow-green-glow;
	}

	&:disabled {
		background: $color-button-primary-disabled;
		color: $color-button-primary-default-label;
	}
}
@mixin longButtonTexts {
	width: auto;
}
@mixin buttonSecondary {
	background: none;
	color: $color-secondary-300;
	background: $color-primary-700;
	font-weight: bold;
	padding: 24px 36px;
	border-radius: 4px;
	border: 1px solid;
	border-color: $color-border-primary;
	&:hover {
		background: $color-primary-500;
	}
	&:active {
		background: $color-primary-500;
	}
	&:disabled {
		color: $color-grey-600;
	}
}
@mixin buttonNav {
	background: none;
	color: #FFFFFF;
	font-weight: 400;
	padding: 16px;
	gap: 4px;
	
	&.active {
		color: $color-secondary-300;
		font-weight: bold;
		span {
			border-bottom: 2px solid $color-secondary-300;
		}
	}
	&:hover {
		color: $color-secondary-300;
		font-weight: bold;
	}

}

@mixin extend-overflow-right($padding) {
	width: calc(100% + $padding);
	padding-right: calc($padding + 1vw);
	box-sizing: border-box;
}